import icon_people from "../assets/different/icon_people.svg";
import icon_chart from "../assets/different/icon_chart.svg";
import icon_environments from "../assets/different/icon_environments.svg";
import icon_data_quality from "../assets/different/icon_data_quality.svg";
import icon_developer from "../assets/different/icon_developer.svg";

export const DifferentData = [
  {
    icon: icon_people,
    text: "Ou team combines in-depth technical knowledge, a strong personalized approach, and a deep understanding of early stages of projects. We excel in formulating creative responses and remedies that align with the unique needs of our clients."
  },
  {
    icon: icon_chart,
    text: "We believe that how we work is as important as what we do. Strong methodology is useless without co-creation to drive the results you are looking for."
  },
  {
    icon: icon_environments,
    text: "We allocate resources to enhance production capacity, drive innovation, ensure efficiency, and prioritize safety. Meanwhile, the aesthetics of our packaging symbolize the achievements of our clients."
  },
  {
    icon: icon_data_quality,
    text: "We approach every piece of work with a commitment to the highest level of quality as well as with integrity, independence, accountability and a social conscience."
  },
  {
    icon: icon_developer,
    text: "We are not only full stack software engineering team, but also a data team, which enables us to deliver products with unique selling proposition such as machine learning or artificial intelligence."
  },
]
import { useEffect, useState, useRef } from 'react';

export function useObserver(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const observerRef = useRef(null);
  const lastScrollPosition = useRef(window.scrollY);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      const currentScrollPosition = window.scrollY;
      // console.log("currentScrollPosition", currentScrollPosition)

      if (currentScrollPosition > lastScrollPosition.current) {
        setScrollDirection('down');
      } else if (currentScrollPosition < lastScrollPosition.current) {
        setScrollDirection('up');
      }

      lastScrollPosition.current = currentScrollPosition;

      setIsOnScreen(entry.isIntersecting);
    });
  }, []);

  useEffect(() => {
    if (ref?.current && observerRef?.current) {observerRef.current.observe(ref.current);}

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return { isOnScreen, scrollDirection };
}
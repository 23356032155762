import axios from "axios";
import { BASE_URL } from "../constants/config";

const RESPONSES_ENDPOINTS = {
  index: `${BASE_URL}api/responses`,
  getId: (id) => `${BASE_URL}api/responses/${id}`,
};

class Responses {
  async get() {
    const response = await axios.post(RESPONSES_ENDPOINTS.index);

    return response.data;
  }

  async create(payload) {
    const response = await axios.post(RESPONSES_ENDPOINTS.index, payload);

    return response.data;
  }

  async delete(id) {
    const response = await axios.delete(RESPONSES_ENDPOINTS.getId(id));

    return response.data;
  }
}

export const ResponsesService = new Responses();

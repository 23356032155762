import './Claims.scss';
import { useEffect, useState } from 'react';
import LottieLoader from '../../../components/Loader/LottieLoader';
import animationDataTheme from '../../../assets/animation/wla.json';
import ArrowBack from '../../../components/Buttons/ArrowBack';
import { getLink } from '../../../services/utils';
import ModeButtons from '../../../components/Buttons/ModeButtons';

const Claims = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [mode, setMode] = useState('claims');

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className="claims-container slow-show">
      <div style={{ display: loading ? 'block' : 'none' }}>
        <LottieLoader
          animationData={animationDataTheme}
          animationWidth={
            currentWidth >= 900
              ? '350px'
              : currentWidth >= 600
              ? '300px'
              : '200px'
          }
          animationHeight={
            currentWidth >= 900
              ? '350px'
              : currentWidth >= 600
              ? '300px'
              : '200px'
          }
        />
      </div>
      <div
        style={{ display: !loading ? 'block' : 'none' }}
        className={!loading ? 'slow-show' : ''}
      >
        <ArrowBack />
        <ModeButtons mode={mode} setMode={setMode} setLoading={setLoading} />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink(`${mode}_header_desktop`)
                  : currentWidth >= 600
                  ? getLink(`${mode}_header_tablet`)
                  : getLink(`${mode}_header_mobile`)
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="main">
          <div className="image-container">
            <img
              onLoad={() => setLoading(false)}
              src={
                currentWidth >= 900
                  ? getLink(`${mode}_main_desktop`)
                  : currentWidth >= 600
                  ? getLink(`${mode}_main_tablet`)
                  : getLink(`${mode}_main_mobile`)
              }
              alt="main"
            />
          </div>
        </div>
        <div className="tech">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink(`${mode}_tech_desktop`)
                  : currentWidth >= 600
                  ? getLink(`${mode}_tech_tablet`)
                  : getLink(`${mode}_tech_mobile`)
              }
              alt="tech"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claims;

import AboutUsImg from '../../assets/home/about-us.png';
import HowWeWork from '../../assets/home/how-we-work.png';
import OurServicesImg from '../../assets/home/our-services.png';
import TechStackImg from '../../assets/home/tech-stack.png';

const AboutUsContent = () => (
  <>
    <p className="h-widget__text">
      Companies thrive when their people,processes and tools are all aligned
      behind shared objectives. At Forvis Mazars, wehelp leaders across the
      business to achieve alignment and deliver peakperformance through
      collaboration, thoughtful advice and up-to-datetechnical and digital
      expertise. 
    </p>
    <p className="h-widget__text">
      Forvis Mazars Global Digital Services is a cross-functional team
      distributed globally applying our practical business experience to deliver
      valuable end-to-end solutions.
    </p>
  </>
);

const HowWeWorkContent = () => (
  <>
    <p className="h-widget__text">
      We provide full-stack development services for a variety of platforms and
      deliver a product fit for your purpose.
    </p>

    <ul className="h-widget__text h-widget__list">
      <li>Project scoping and agreement</li>
      <li>UX/UI design</li>
      <li>MVP validation</li>
      <li>Development and Testing</li>
      <li>Handover</li>
    </ul>
  </>
);

const OurServicesContent = () => (
  <>
    <ul className="h-widget__text h-widget__list">
      <li>Architecture review</li>
      <li>Software development life cycle implementation</li>
      <li>Business requirement gathering</li>
      <li>UX/UI Design</li>
      <li>Full stack development</li>
      <li>CI/CD implementation</li>
      <li>Software maintenance</li>
    </ul>
  </>
);

const TechnologyStack = () => (
  <>
    <p className="h-widget__text">
      As technological change spreads and advances, organisations are embracing
      digital transformation. Modern architectures such as cloud and
      microservices can be the key to enabling digital businesses to stay ahead
      of the competition. Our specialists combine technology with business
      strategy to create seamless business operations that will put you on track
      for growth.
    </p>
    <p className="h-widget__text">
      At Forvis Mazars, our aim is to help organisations take advantage of
      enterprise solutions, identifying opportunities to improve performance,
      reduce costs, manage risks and drive innovation. We challenge traditional
      business models and inspire organisations to think creatively to harness
      the potential of technology as a competitive differentiator.
    </p>
  </>
);

export const HOME_WIDGETS = [
  {
    title: 'About Us',
    content: <AboutUsContent />,
    position: 1,
    color: '#fff',
    image: AboutUsImg,
    href: '/about-us',
  },
  {
    title: 'How we work',
    content: <HowWeWorkContent />,
    position: 2,
    color: '#3D4975',
    image: HowWeWork,
    href: '/how-we-work',
  },
  {
    title: 'Our services',
    content: <OurServicesContent />,
    position: 1,
    color: '#fff',
    image: OurServicesImg,
    href: '/our-services',
  },
  {
    title: 'Technology stack',
    content: <TechnologyStack />,
    position: 2,
    color: '#457E8F',
    image: TechStackImg,
    href: '/technology-stack',
  },
];

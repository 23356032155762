// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  height: 100%;
  border: 1px solid #c3c3c3;
  border-style: dashed;
  border-collapse: collapse;
}
table tr:not(:last-child) td {
  border-bottom-color: white;
}
table td,
table th {
  border: 1px solid #c3c3c3;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
@media screen and (min-width: 600px) {
  table td,
  table th {
    font-size: 14px;
  }
}
@media screen and (min-width: 1152px) {
  table td,
  table th {
    line-height: 25.2px;
    font-size: 18px;
  }
}
table th {
  background-color: #f4f4f4;
  font-family: var(--font-halyard);
  text-align: center;
  height: 50px;
}
table td {
  border-style: dashed;
  position: relative;
}

.header-sub-title {
  font-size: 10px;
  font-weight: normal;
}
@media screen and (min-width: 600px) {
  .header-sub-title {
    font-size: 12px;
  }
}
@media screen and (min-width: 1152px) {
  .header-sub-title {
    font-size: 14px;
  }
}

.arrow-container {
  position: absolute;
  top: 20px;
  left: -1px;
  z-index: 1;
  cursor: pointer !important;
  transition: 0.3s;
}
.arrow-container__scale {
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/HowWeWork/components/timeframe_table/TimeframeTable.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,oBAAA;EACA,yBAAA;AACF;AAAE;EACE,0BAAA;AAEJ;AACE;;EAEE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAAI;EANF;;IAOI,eAAA;EAIJ;AACF;AADI;EAXF;;IAYI,mBAAA;IACA,eAAA;EAKJ;AACF;AADE;EACE,yBAAA;EACA,gCAAA;EACA,kBAAA;EACA,YAAA;AAGJ;AAEE;EACE,oBAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,eAAA;EACA,mBAAA;AADF;AAEE;EAHF;IAII,eAAA;EACF;AACF;AAAE;EANF;IAOI,eAAA;EAGF;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,0BAAA;EACA,gBAAA;AAEF;AADE;EACE,MAAA;AAGJ","sourcesContent":["table {\n  width: 100%;\n  height: 100%;\n  border: 1px solid #c3c3c3;\n  border-style: dashed;\n  border-collapse: collapse;\n  tr:not(:last-child) td {\n    border-bottom-color: white;\n  }\n\n  td,\n  th {\n    border: 1px solid #c3c3c3;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 20px;\n    @media screen and (min-width: 600px) {\n      font-size: 14px;\n\n     }\n    \n    @media screen and (min-width: 1152px) {\n      line-height: 25.2px;\n      font-size: 18px;\n     }\n    }\n\n\n  th {\n    background-color: #f4f4f4;\n    font-family: var(--font-halyard);\n    text-align: center;\n    height: 50px;\n\n \n  \n  }\n  td {\n    border-style: dashed;\n    position: relative;\n  }\n}\n\n.header-sub-title{\n  font-size: 10px;\n  font-weight: normal;  \n  @media screen and (min-width: 600px) {\n    font-size: 12px;\n   }\n  @media screen and (min-width: 1152px) {\n    font-size: 14px;\n   }\n   }\n    \n\n.arrow-container {\n  position: absolute;\n  top: 20px;\n  left: -1px;\n  z-index: 1;\n  cursor: pointer !important;\n  transition: .3s;\n  &__scale{\n    top: 0;\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

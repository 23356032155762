export const ServiceItem = (props) => {
  return (
    <div className="">
      {/* <img
        src={props.isMobile ? props.item.mobile_img : props.item.decstop_img}
        alt="service-icon"
      /> */}
      <div className="">
        <div className="service__item">
          <h3 className="service-item__title">{props.item.title}</h3>
          <div className="service-item__list-content">
            {Object.keys(props.item.services_list).map((list, idx) => {
              return (
                <ul className="service-item__list" key={idx}>
                  {props.item.services_list[list].map((el, idx) => (
                    <li key={idx}>{el}</li>
                  ))}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

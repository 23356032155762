import React from "react";

const list = [
  `Ou team combines in-depth technical knowledge, a strong personalized approach, and a deep understanding of early stages of projects.`,
  `We excel in formulating creative responses and remedies that align with the unique needs of our clients.`,
  `We believe that how we work is as important as what we do.`,
  `Strong methodology is useless without co-creation to drive the results you are looking for.`,
  `We allocate resources to enhance production capacity, drive innovation, ensure efficiency, and prioritize safety. Meanwhile, the aesthetics of our packaging symbolize the achievements of our clients.`,
  `We approach every piece of work with a commitment to the highest level of quality as well as with integrity, independence, accountability and a social conscience.`,
  `We are not only full stack software engineering team, but also a data team, which enables us to deliver products with unique selling proposition such as machine learning or artificial intelligence.`,
];

export default function Different() {
  return (
    <div className="m-card" style={{ backgroundColor: "#704B62" }}>
      <div className="m-card__content">
        <h3 className="m-card__title">What makes us different</h3>
        <ul className="m-card__list">
          {list.map((i) => (
            <li key={i}>{i}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

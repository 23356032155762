import React, { useEffect, useState } from "react";
import "./TechnologyStack.scss";
import { TechnologyStackData } from "../../data/TechnologyStack";
import BlueDot from "../../assets/tech_stack/blue_dot.svg";
import ArrowUp from "../../assets/home/arrow_blue_up.svg";

const TechnologyStack = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="m-card" style={{ backgroundColor: "#457E8F" }}>
        <div className="m-card__content">
          <div className="tech-stack__content container">
            {TechnologyStackData.map((el, index) => (
              <div className="tech-stack__item" key={index}>
                <div className="tech-stack__title">{el.title}</div>
                <div className="tech-stack__list">
                  {el.techItems.map((item, idx) => (
                    <div className="item-card" key={idx}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>
                {/* <img src={BlueDot} /> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isVisible && (
        <div className="button-to-start">
          <img src={ArrowUp} alt="ArrowUp" onClick={scrollToTop} />
        </div>
      )}
    </div>
  );
};

export default TechnologyStack;

import React from 'react';
import './HomeWidget.scss';
import AboutUsImg from '../../assets/home/about-us.png';
import { useNavigate } from 'react-router-dom';

export default function HomeWidget({
  title,
  style,
  position = 1,
  content,
  image,
  href,
}) {
  const router = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={position === 1 ? 'h-widget' : 'h-widget reverse'}
      style={style}
    >
      <div className={'container h-widget__wrapper'}>
        <div className={'h-widget__desc'}>
          <h3 className="h-widget__title">{title}</h3>

          {content}

          <button
            onClick={() => {
              router(href);
              scrollToTop();
            }}
            className="m-button read-more-btn m-button-white"
          >
            Read more
          </button>
        </div>
        <div
          className="h-widget__img"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          {/* <img src={AboutUsImg} alt="" /> */}
        </div>
      </div>
    </div>
  );
}

import { ServicesData } from "../data/Services";

const Platforms = () => {
  return (
    <div className="platforms">
      {ServicesData.map((el, index) => (
        <div className="platforms-card" key={index}>
          <img src={el.image} />
          <div className="body-text" style={{ lineHeight: '28px', textTransform: 'none' }}>{el.title}</div>
        </div>
      ))
      }
    </div>
  )
}

export default Platforms;
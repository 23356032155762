import React from "react";
import "./style.scss";

const needs = [
  "You have identified the market gap to fill, worked out your idea and offer, raised funds to start your business.",
  "Now time is of essence: the sooner you launch your product, the sooner you can test it against reality, get market feedback, widen your fundraising and focus on business growth..",
  "Whether you have in-house expertise or not, however advanced your project already is, you can count on our specialists to design, develop or strengthen your offer. They know the solutions and technology inside-out and will work hand in hand with you to bring your ideas to life.",
  "We believe every organization deserves the right technological fit; and align our strategies and tools perfectly with your goals, to ensure a bespoke solution that resonates with your vision.",
];

const solutions = [
  "Accompany leaders from idea to product: Business analysis, Feature prioritization, UX/UI, Minimum Viable Product.",
  "Deliver a data centric full stack software development team: Project manager, UI/UX designer, Technical manager, Front-end developer, Back-end engineer, Data engineer, Data scientist,",
  "Ensure the flexibly and scalability of your solution : Documentation, Code review, CI/CD, Penetration tests and security standards,",
  "Prepare future growth and your team’s empowerment: Maintenance and support, Handover and Trainings.",
];

export default function NeedsSolutions() {
  return (
    <div
      className="m-card"
      style={{ backgroundColor: "#F4F4F4", paddingBottom: 0 }}
    >
      <div className="ns-wrapper">
        <div className="m-card__content">
          <h3 className="m-card__title">Your needs</h3>
          {needs.map((i) => (
            <p key={i} className="m-card__text">
              {i}
            </p>
          ))}
        </div>
        <div className="m-card__content" style={{ backgroundColor: "#F4F4F4" }}>
          <h3 className="m-card__title">Our solutions</h3>
          {solutions.map((i) => (
            <p key={i} className="m-card__text">
              {i}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

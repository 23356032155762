import { useNavigate } from "react-router-dom";
import { DifferentData } from "../data/Different";
import { forwardRef } from "react";

const Different = forwardRef(({noButton}, ref) => {
  const router = useNavigate();

  return (
    <div className="home-different" ref={ref}>
      <h2>What makes us different</h2>
      <div className="different-content">
        {DifferentData.map((el, index) => (
          <div className="different-box" key={index}>
            <img src={el.icon} alt={el.icon} />
            <div className="card-content">{el.text}</div>
          </div>
        ))}
      </div>
      {!noButton && <div className="button-blue" onClick={() => router('/about-us')}>About us</div>}
    </div>
  )
})

export default Different;

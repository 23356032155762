import { useState } from "react";
import { SchedulePopup } from "../SchedulePopup/SchedulePopup";

export const ScheduleADiscutionBtn = () => {
  //   const clickToRedirect = () => {
  //     window.location.href =
  //       "https://outlook.office.com/bookwithme/user/7c3b1a344b3b47049e012be8d45331a5%400416d70d-c65d-4c29-9f82-ac7e26ac192f/meetingtype/38da894b-a9f2-43e2-9869-a4ecac38a9fc?anonymous";
  //   };

  let [isVisible, setVisible] = useState(false);

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <div>
      <SchedulePopup isOpen={isVisible} closeModal={closeModal} />
      <div className="m-button m-button-blue" onClick={() => setVisible(true)}>
        Admin access
      </div>
    </div>
  );
};

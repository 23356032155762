import { RouterProvider } from "react-router-dom";
import router from "./router/Routing";
import { createContext } from "react";
import CustomContextProvider from "./context/Context";

export default function App() {
  const initContext = {
    anchorRefs: [],
    prevBlock: null,
    currentBlock: null,
    nextBlock: null,
  };
  const Context = createContext(initContext);

  return (
  <div className="page-content">
      <CustomContextProvider>
        <RouterProvider router={router} />
      </CustomContextProvider>
  </div>
  );
}

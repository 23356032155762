import claims from "../../assets/buttons/claims.png";
import claims_selected from "../../assets/buttons/claims_selected.png";
import mazars from "../../assets/buttons/mazars.png";
import mazars_selected from "../../assets/buttons/mazars_selected.png";
import wla from "../../assets/buttons/wla.png";
import wla_selected from "../../assets/buttons/wla_selected.png";
import { useNavigate } from "react-router-dom";

const ModeButtons = ({
  mode,
  setMode,
  setLoading,
}) => {
  const themeHandler = (targetMode) => {
    if (mode !== targetMode) {
      setLoading(true);
      setMode(targetMode);
    }
  }

  return (
    <div className="mode-buttons">
      <div className={`mode-wla ${mode === "wla" ? "selected" : ""}`} onClick={() => themeHandler("wla")}>
        <img src={mode === "wla" ? wla_selected : wla} alt="wla" />
      </div>
      <div className={`mode-mazars ${mode === "mazars" ? "selected" : ""}`} onClick={() => themeHandler("mazars")}>
        <img src={mode === "mazars" ? mazars_selected : mazars} alt="mazars" />
      </div>
      <div className={`mode-claims ${mode === "claims" ? "selected" : ""}`} onClick={() => themeHandler("claims")}>
        <img src={mode === "claims" ? claims_selected : claims} alt="claims" />
      </div>
    </div>
  )
}

export default ModeButtons;
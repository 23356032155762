import "./DataMorph.scss";
import { useEffect, useState } from "react";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/datamorph_3.json";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import { getLink } from "../../../services/utils";

// import datamorph_header_computer from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_header_computer.png";
// import datamorph_header_tablet from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_header_tablet.png";
// import datamorph_header_mobile from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_header_mobile.png";

// import datamorph_body_computer from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_body_computer.png";
// import datamorph_body_tablet from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_body_tablet.png";
// import datamorph_body_mobile from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_body_mobile.png";

// import datamorph_technology_computer from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_technology_computer.png";
// import datamorph_technology_tablet from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_technology_tablet.png";
// import datamorph_technology_mobile from "../../../assets/projects/ProjectsPagesContent/DataMorph/datamorph_technology_mobile.png";


const DataMorph = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])

  return (
    <div className="datamorph-container">
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
          animationHeight={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("datamorph_header_computer")
                  : currentWidth >= 600
                    ? getLink("datamorph_header_tablet")
                    : getLink("datamorph_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="body">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("datamorph_body_computer")
                  : currentWidth >= 600
                    ? getLink("datamorph_body_tablet")
                    : getLink("datamorph_body_mobile")
              }
              alt="body"
            />
          </div>
        </div>
        <div className="technology">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("datamorph_technology_computer")
                  : currentWidth >= 600
                    ? getLink("datamorph_technology_tablet")
                    : getLink("datamorph_technology_mobile")
              }
              alt="technology"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataMorph;

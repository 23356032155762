import React, { useCallback, useState } from 'react';
import './filter.scss';
import Search from '../../assets/icons/search';
import Strategy from '../../assets/icons/strategy';
import DataCentralization from '../../assets/icons/data-centralization';
import Pipeline from '../../assets/icons/pipeline';
import MachineLearning from '../../assets/icons/machine-learning';
import AI from '../../assets/icons/AI';
import BI from '../../assets/icons/BI';
import LowCode from '../../assets/icons/low-code';
import AutomatedScripts from '../../assets/icons/automated-scripts';
import SoftwareDevelopment from '../../assets/icons/software-development';

const filters = [
  {
    name: 'Digital strategy',
    icon: Strategy,
  },
  {
    name: 'Data management',
    icon: DataCentralization,
  },
  {
    name: 'Data pipeline',
    icon: Pipeline,
  },
  {
    name: 'Machine learning',
    icon: MachineLearning,
  },
  {
    name: 'AI',
    icon: AI,
  },
  {
    name: 'BI',
    icon: BI,
  },
  {
    name: 'Security management',
    icon: LowCode,
  },
  {
    name: 'Full stack software development',
    icon: SoftwareDevelopment,
  },
  {
    name: 'Automated scripts',
    icon: AutomatedScripts,
  },
];

export default function ProjectFilter({ onSearch, onTagsSelect }) {
  const [tags, setTags] = useState([]);

  const onSelectTag = useCallback(
    (tag) => {
      let _tags = [];
      if (tags.includes(tag)) {
        _tags = tags.filter((t) => t !== tag);
      } else {
        _tags = [...tags, tag];
      }
      setTags(_tags);
      onTagsSelect(_tags);
    },
    [tags, onTagsSelect]
  );

  return (
    <div className="pr-filter">
      <div className="pr-filter__search">
        <input
          className="pr-filter__input"
          placeholder="Search"
          onChange={({ target }) => onSearch(target.value)}
        />
        <Search className="pr-filter__search-icon" />
      </div>
      <ul className="pr-filter__list">
        {filters.map((i) => {
          const Icon = i.icon;
          return (
            <li
              className={
                tags.includes(i.name)
                  ? 'pr-filter__item selected'
                  : 'pr-filter__item'
              }
              onClick={() => onSelectTag(i.name)}
              key={i.name}
            >
              <Icon className="pr-filter__icon" name={i.name} />
              <span>{i.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

import onpoint from '../assets/brands/onpoint.png';
import total_energies from '../assets/brands/total_energies.png';
import premier from '../assets/brands/premier.png';
import panda from '../assets/brands/panda.png';
import anemon from '../assets/brands/anemon.png';
import forvis_mazars from '../assets/brands/ForvisMazars-Logo-Color.png';
import heppner from '../assets/brands/heppner-logo.png';
import astellas from '../assets/brands/astellas.png';

export const BrandsData = [
  { image: onpoint },
  { image: total_energies },
  { image: premier },
  { image: panda },
  { image: anemon },
  { image: forvis_mazars },
  { image: heppner },
  { image: astellas },
];

import React, { useEffect, useState } from "react";
import "./Maintenance.scss";
import { getLink } from "../../services/utils";

const Maintenance = () => {
  // const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <div>
      <div className="maintenance-container">
        <div className="image-container">
          <img
            src={
              currentWidth >= 900
                ? getLink("maintenace_maintenace_desktop")
                : currentWidth >= 600
                ? getLink("maintenace_maintenace_tablet")
                : getLink("maintenace_maintenace_mobile")
            }
            alt="maintenace-block"
          />
        </div>
        <div className="image-container">
          <img
            src={
              currentWidth >= 900
                ? getLink("maintenace_infrastructure_desktop")
                : currentWidth >= 600
                ? getLink("maintenace_infrastructure_tablet")
                : getLink("maintenace_infrastructure_mobile")
            }
            alt="features"
          />
        </div>
        <div className="image-container">
          <img
            src={
              currentWidth >= 900
                ? getLink("maintenace_description_desktop")
                : currentWidth >= 600
                ? getLink("maintenace_description_tablet")
                : getLink("maintenace_description_mobile")
            }
            alt="description"
          />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;

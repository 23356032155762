import React from "react";

export default function Strategy({ fill = "#434343", ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6497 1.548C20.4247 1.32303 20.1195 1.19666 19.8013 1.19666C19.4831 1.19666 19.1779 1.32303 18.9529 1.548L17.1481 3.3516C16.9231 3.57663 16.7968 3.8818 16.7968 4.2C16.7968 4.5182 16.9231 4.82337 17.1481 5.0484L18.9517 6.852C19.1767 7.07697 19.4819 7.20334 19.8001 7.20334C20.1183 7.20334 20.4235 7.07697 20.6485 6.852L22.4521 5.0484C22.6771 4.82337 22.8034 4.5182 22.8034 4.2C22.8034 3.8818 22.6771 3.57663 22.4521 3.3516L20.6497 1.548ZM17.9977 4.2L19.8013 2.3964L21.6049 4.2L19.8001 6.0036L17.9977 4.2ZM15.5977 3.6H6.6001C5.80445 3.6 5.04139 3.91607 4.47878 4.47868C3.91617 5.04129 3.6001 5.80435 3.6001 6.6V17.4C3.6001 18.1957 3.91617 18.9587 4.47878 19.5213C5.04139 20.0839 5.80445 20.4 6.6001 20.4H17.4001C18.1957 20.4 18.9588 20.0839 19.5214 19.5213C20.084 18.9587 20.4001 18.1957 20.4001 17.4V8.4H15.5977V3.6ZM6.4273 19.1916C5.98191 19.1486 5.56848 18.9413 5.26764 18.6101C4.9668 18.2789 4.80012 17.8475 4.8001 17.4V15.6H8.4001V19.2H6.6001L6.4273 19.1916ZM9.6001 15.6H14.4001V19.2H9.6001V15.6ZM19.1917 17.5728C19.1487 18.0182 18.9414 18.4316 18.6102 18.7325C18.279 19.0333 17.8476 19.2 17.4001 19.2H15.6001V15.6H19.2001V17.4L19.1917 17.5728ZM9.6001 9.6H14.4001V14.4H9.6001V9.6ZM15.6001 14.4V9.6H19.2001V14.4H15.6001ZM9.6001 4.8H14.4001V8.4H9.6001V4.8ZM6.6001 4.8H8.4001V8.4H4.8001V6.6L4.8085 6.426C4.85173 5.98082 5.05916 5.56769 5.39037 5.2671C5.72157 4.96651 6.15283 4.79999 6.6001 4.8ZM4.8001 14.4V9.6H8.4001V14.4H4.8001Z"
        fill={fill}
      />
    </svg>
  );
}

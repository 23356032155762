// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BouncingsLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.BouncingsLoader .dot {
  width: 20px;
  height: 20px;
  background-color: #0071ce;
  border-radius: 20px;
  transform: translate(0px, -40px);
  animation: bounce 1s infinite;
}
.BouncingsLoader .dot:nth-child(2) {
  animation-delay: 0.2s;
}
.BouncingsLoader .dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes bounce {
  0% {
    transform: translate(0px, -10px);
  }
  40% {
    width: 0px;
    height: 2px;
    transform: translate(0px, 40px) scale(1.7);
  }
  100% {
    height: 20px;
    transform: translate(0px, -20px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loaders/Loaders.scss"],"names":[],"mappings":"AAAE;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACN;AACM;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,gCAAA;EACA,6BAAA;AACV;AAEM;EACI,qBAAA;AAAV;AAGM;EACI,qBAAA;AADV;AAIM;EACI;IACI,gCAAA;EAFZ;EAKQ;IACI,UAAA;IACA,WAAA;IACA,0CAAA;EAHZ;EAMQ;IACI,YAAA;IACA,gCAAA;EAJZ;AACF","sourcesContent":["  .BouncingsLoader {\n      width: 100%;\n      height: 100%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 50px;\n\n      .dot {\n          width: 20px;\n          height: 20px;\n          background-color: #0071ce;\n          border-radius: 20px;\n          transform: translate(0px, -40px);\n          animation: bounce 1s infinite;\n      }\n\n      .dot:nth-child(2) {\n          animation-delay: 0.2s;\n      }\n\n      .dot:nth-child(3) {\n          animation-delay: 0.4s;\n      }\n\n      @keyframes bounce {\n          0% {\n              transform: translate(0px, -10px);\n          }\n\n          40% {\n              width: 0px;\n              height: 2px;\n              transform: translate(0px, 40px) scale(1.7);\n          }\n\n          100% {\n              height: 20px;\n              transform: translate(0px, -20px);\n          }\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from "react";
import "./Premier.scss";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/premier_1.json";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import { getLink } from "../../../services/utils";

// import premier_header_desktop from "../../../assets/projects/ProjectsPagesContent/Premier/premier_header_desktop.png";
// import premier_header_tablet from "../../../assets/projects/ProjectsPagesContent/Premier/premier_header_tablet.png";
// import premier_header_mobile from "../../../assets/projects/ProjectsPagesContent/Premier/premier_header_mobile.png";

// import premier_solution_desktop from "../../../assets/projects/ProjectsPagesContent/Premier/premier_solution_desktop.png";
// import premier_solution_tablet from "../../../assets/projects/ProjectsPagesContent/Premier/premier_solution_tablet.png";
// import premier_solution_mobile from "../../../assets/projects/ProjectsPagesContent/Premier/premier_solution_mobile.png";

// import premier_technology_desktop from "../../../assets/projects/ProjectsPagesContent/Premier/premier_technology_desktop.png";
// import premier_technology_tablet from "../../../assets/projects/ProjectsPagesContent/Premier/premier_technology_tablet.png";
// import premier_technology_mobile from "../../../assets/projects/ProjectsPagesContent/Premier/premier_technology_mobile.png";

// import premier_devices_desktop from "../../../assets/projects/ProjectsPagesContent/Premier/premier_devices_desktop.png";
// import premier_devices_tablet from "../../../assets/projects/ProjectsPagesContent/Premier/premier_devices_tablet.png";
// import premier_devices_mobile from "../../../assets/projects/ProjectsPagesContent/Premier/premier_devices_mobile.png";


const Premier = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])


  return (
    <div className="premier-container">
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "450px" : (currentWidth >= 600 ? "350px" : "250px")}
          animationHeight={currentWidth >= 900 ? "450px" : (currentWidth >= 600 ? "350px" : "250px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("premier_header_desktop")
                  : currentWidth >= 600
                    ? getLink("premier_header_tablet")
                    : getLink("premier_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="solution">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("premier_solution_desktop")
                  : currentWidth >= 600
                    ? getLink("premier_solution_tablet")
                    : getLink("premier_solution_mobile")
              }
              alt="solution"
            />
          </div>
        </div>
        <div className="technology">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("premier_technology_desktop")
                  : currentWidth >= 600
                    ? getLink("premier_technology_tablet")
                    : getLink("premier_technology_mobile")
              }
              alt="technology"
            />
          </div>
        </div>
        <div className="devices">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("premier_devices_desktop")
                  : currentWidth >= 600
                    ? getLink("premier_devices_tablet")
                    : getLink("premier_devices_mobile")
              }
              alt="devices"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premier;

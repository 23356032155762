// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-arrow-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  z-index: 2;
}
.step-arrow-container .step {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  font-family: var(--font-halyard);
  font-weight: 500;
  z-index: 1;
  color: #ffffff;
}
.step-arrow-container .step-title {
  transition: 0.3s;
  font-size: 12px;
  z-index: 5;
  margin-left: 30px;
}
@media screen and (min-width: 900px) {
  .step-arrow-container .step-title {
    font-size: 12px;
  }
}
@media screen and (min-width: 1152px) {
  .step-arrow-container .step-title {
    font-size: 14px;
  }
}
@media screen and (min-width: 1440px) {
  .step-arrow-container .step-title {
    font-size: 18px;
  }
}
.step-arrow-container .step .step-triangle {
  position: absolute;
  rotate: 45deg;
  width: 28px;
  height: 28px;
  right: -15px;
  top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/HowWeWork/components/step/Step.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,WAAA;EACA,UAAA;AACJ;AAAI;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,gBAAA;EACA,UAAA;EAkBA,cAAA;AAfN;AAFM;EACE,gBAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AAIR;AAFQ;EANF;IAOI,eAAA;EAKR;AACF;AAJQ;EATF;IAUI,eAAA;EAOR;AACF;AANQ;EAZF;IAaI,eAAA;EASR;AACF;AALM;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,QAAA;AAOR","sourcesContent":[".step-arrow-container{\n    display: flex;\n    justify-content: flex-start;\n    width: 100%;\n    z-index: 2;\n    .step {\n      display: inline-flex;\n      flex-direction: row;\n      justify-content: center;\n      align-items: center;\n      position: relative;\n      height:40px;\n      font-family: var(--font-halyard);\n      font-weight: 500;\n      z-index: 1;\n      &-title{\n        transition: 0.3s;\n        font-size: 12px;\n        z-index: 5;\n        margin-left: 30px;\n        \n        @media screen and (min-width: 900px) {\n          font-size: 12px;\n        }\n        @media screen and (min-width: 1152px) {\n          font-size: 14px;\n        }\n        @media screen and (min-width: 1440px) {\n          font-size: 18px;\n        }\n\n      }\n      color: #ffffff;\n      .step-triangle{\n        position: absolute;\n        rotate: 45deg;\n        width: 28px;\n        height: 28px;\n        right: -15px;\n        top: 6px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import "./Panda.scss";
import { useEffect, useState } from "react";
// import PandaLogo from "../../../assets/animation/panda_animation.gif";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/panda_1.json";
import { getLink } from "../../../services/utils";

// import header_computer from "../../../assets/projects/ProjectsPagesContent/Panda/header_computer.png";
// import header_tablet from "../../../assets/projects/ProjectsPagesContent/Panda/header_tablet.png";
// import header_mobile from "../../../assets/projects/ProjectsPagesContent/Panda/header_mobile.png";

// import features_computer from "../../../assets/projects/ProjectsPagesContent/Panda/features_computer.png";
// import features_tablet from "../../../assets/projects/ProjectsPagesContent/Panda/features_tablet.png";
// import features_mobile from "../../../assets/projects/ProjectsPagesContent/Panda/features_mobile.png";

// import timeframe_computer from "../../../assets/projects/ProjectsPagesContent/Panda/timeframe_computer.png";
// import timeframe_tablet from "../../../assets/projects/ProjectsPagesContent/Panda/timeframe_tablet.png";
// import timeframe_mobile from "../../../assets/projects/ProjectsPagesContent/Panda/timeframe_mobile.png";

// import technology_computer from "../../../assets/projects/ProjectsPagesContent/Panda/technology_computer.png";
// import technology_tablet from "../../../assets/projects/ProjectsPagesContent/Panda/technology_tablet.png";
// import technology_mobile from "../../../assets/projects/ProjectsPagesContent/Panda/technology_mobile.png";

// import mobile_computer from "../../../assets/projects/ProjectsPagesContent/Panda/mobile_computer.png";
// import mobile_tablet from "../../../assets/projects/ProjectsPagesContent/Panda/mobile_tablet.png";
// import mobile_mobile from "../../../assets/projects/ProjectsPagesContent/Panda/mobile_mobile.png";


const Panda = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])

  return (
    <div className="panda-container">
      {/* <div style={{ display: loading ? "block" : "none" }}>
        <div className="panda-loader">
          <img className={currentWidth >= 600 ? "panda-show" : "panda-show-mobile"} src={PandaLogo} alt="Panda" />
        </div>
      </div> */}
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
          animationHeight={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("panda_header_computer")
                  : currentWidth >= 600
                    ? getLink("panda_header_tablet")
                    : getLink("panda_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="features">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("panda_features_computer")
                  : currentWidth >= 600
                    ? getLink("panda_features_tablet")
                    : getLink("panda_features_mobile")
              }
              alt="features"
            />
          </div>
        </div>
        <div className="timeframe">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("panda_timeframe_computer")
                  : currentWidth >= 600
                    ? getLink("panda_timeframe_tablet")
                    : getLink("panda_timeframe_mobile")
              }
              alt="timeframe"
            />
          </div>
        </div>
        <div className="technology">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("panda_technology_computer")
                  : currentWidth >= 600
                    ? getLink("panda_technology_tablet")
                    : getLink("panda_technology_mobile")
              }
              alt="technology"
            />
          </div>
        </div>
        <div className="mobile">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("panda_mobile_computer")
                  : currentWidth >= 600
                    ? getLink("panda_mobile_tablet")
                    : getLink("panda_mobile_mobile")
              }
              alt="mobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panda;

import Home from "../assets/header/for_pages/home.png";
import AboutUs from "../assets/header/for_pages/about.png";
import TechStack from "../assets/header/for_pages/tech_stack.png";
import HowWeWork from "../assets/header/for_pages/how_we_work.png";
import OurServices from "../assets/header/for_pages/our_services.png";

export const HeaderData =
{
  "/": Home,
  "/about-us": AboutUs,
  "/technology-stack": TechStack,
  "/how-we-work": HowWeWork,
  "/our-services": OurServices,
}
import React from "react";

import "./buttons.scss";

const SquareButton = ({
  text = "",
  textColor = "#FFFFFF",
  bgColor = "#0A1F8F",
  ...buttonProps
}) => {
  return (
    <button
      style={{
        color: textColor,
        backgroundColor: bgColor,
      }}
      className="square-btn"
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default SquareButton;

import React from "react";

import "./buttons.scss";
import { useNavigate } from "react-router-dom";


const FixedBackButton = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="fixed-back-btn__wrapper" onClick={goBack}>
      <button class="fixed-back-btn">
        <span>
            &#8592;
        </span>
      </button>
    </div>
  );
};

export default FixedBackButton;

export const AboutUsData = [
  {
    need: "You have identified the market gap to fill, worked out your idea and offer, raised funds to start your business.",
    solution: "Accompany leaders from idea to product: Business analysis, Feature prioritization, UX/UI, Minimum Viable Product.",
  },
  {
    need: "Now time is of essence: the sooner you launch your product, the sooner you can test it against reality, get market feedback, widen your fundraising and focus on business growth.",
    solution: "Deliver a data centric full stack software development team: Project manager, UI/UX designer, Technical manager, Front-end developer, Back-end engineer, Data engineer, Data scientist.",
  },
  {
    need: "Whether you have in-house expertise or not, however advanced your project already is, you can count on our specialists to design, develop or strengthen your offer. They know the solutions and technology inside-out and will work hand in hand with you to bring your ideas to life.",
    solution: "Ensure the flexibly and scalability of your solution : Documentation, Code review, CI/CD, Penetration tests and security standards,",
  },
  {
    need: "We believe every organization deserves the right technological fit; and align our strategies and tools perfectly with your goals, to ensure a bespoke solution that resonates with your vision.",
    solution: "Prepare future growth and your team`s empowerment: Maintenance and support, Handover and Trainings.",
  },
]
import './UCertify.scss';
import { useEffect, useState } from 'react';
import LottieLoader from '../../../components/Loader/LottieLoader';
import animationData from '../../../assets/animation/u-certify.json';
import ArrowBack from '../../../components/Buttons/ArrowBack';
import { getLink } from '../../../services/utils';

const UCertify = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className="u-certify-container">
      <div style={{ display: loading ? 'block' : 'none' }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={
            currentWidth >= 900
              ? '450px'
              : currentWidth >= 600
              ? '350px'
              : '250px'
          }
          animationHeight={
            currentWidth >= 900
              ? '450px'
              : currentWidth >= 600
              ? '350px'
              : '250px'
          }
        />
      </div>
      <div
        style={{ display: !loading ? 'block' : 'none' }}
        className={!loading ? 'slow-show' : ''}
      >
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink('u-certify_header_desktop')
                  : currentWidth >= 600
                  ? getLink('u-certify_header_tablet')
                  : getLink('u-certify_header_mobile')
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="main">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink('u-certify_main_desktop')
                  : currentWidth >= 600
                  ? getLink('u-certify_main_tablet')
                  : getLink('u-certify_main_mobile')
              }
              alt="main"
            />
          </div>
        </div>
        <div
          className="u-certify-flow"
          style={
            currentWidth >= 900
              ? { marginBottom: '-1.9%' }
              : currentWidth >= 600
              ? { marginBottom: '-4.6%' }
              : { marginBottom: '-7.7%' }
          }
        >
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink('u-certify_flow_desktop')
                  : currentWidth >= 600
                  ? getLink('u-certify_flow_tablet')
                  : getLink('u-certify_flow_mobile')
              }
              alt="credential-flow-color-theme"
            />
          </div>
        </div>
        {/* <div className="technology-colors-typography">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink('anemon_tech_colors_desktop')
                  : currentWidth >= 600
                  ? getLink('anemon_tech_colors_tablet')
                  : getLink('anemon_tech_colors_mobile')
              }
              alt="technology-colors-typography"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default UCertify;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  cursor: default;
}
.overlay .container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: 80vh;
  width: 80vw;
  z-index: 5;
  display: flex;
  flex-direction: column;
}
.overlay .container .body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.overlay .container .body .book-time-block {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/SchedulePopup/SchedulePopup.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAGN;AAFM;EACE,WAAA;EACA,YAAA;AAIR","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 9999;\n  cursor: default;\n  .container {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    height: 80vh;\n    width: 80vw;\n    z-index: 5;\n    display: flex;\n    flex-direction: column;\n    .body {\n      display: flex;\n      flex-direction: row;\n      width: 100%;\n      height: 100%;\n      .book-time-block {\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import './Footer.scss';
import MainLogo from '../../assets/brands/brand-mazars-logo.png';
import BlueLogo from '../../assets/brands/ForvisMazars-Logo-Color.png';
import Copyright from '../../assets/footer/Copyright.svg';
import { HEADER_LINKS } from '../../constants/AppConstants';
import { NavLink, useNavigate } from 'react-router-dom';
import { ScheduleADiscutionBtn } from '../Buttons/ScheduleADiscutionBtn';
import { useCustomContext } from '../../context/Context';

const Footer = () => {
  const { setIsLoginPageOpen, isLoginPageOpen, isAdmin } = useCustomContext();
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scheduleDisscussion = () => {
    if (!isAdmin) {
      setIsLoginPageOpen((prev) => !prev);
      return;
    }

    navigate('/admin');
  };

  return (
    <footer className='footer'>
      <div className='footer-main'>
        <div className='footer-logo'>
          <img src={BlueLogo} alt='Mazars' />
        </div>
        <div className='footer-nav'>
          <div className='navigation-buttons'>
            <div className='navigation-bar'>
              {HEADER_LINKS.map((i) => (
                <NavLink key={i.href} className='nav-item' to={i.href}>
                  <div onClick={scrollToTop}>{i.title}</div>
                </NavLink>
              ))}
            </div>
            <div className='footer-actions'>
              {/* <div className="m-button m-button-white">Request a quote</div> */}
              <div className="m-button m-button-blue" onClick={() => scheduleDisscussion()} >
                Admin access
            </div>
              {/* <ScheduleADiscutionBtn /> */}
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <img src={Copyright} alt='' />
        <span className='body-text'>Forvis Mazars 2023</span>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

import "./Loaders.scss"


const BouncingsLoader = () => {
  return (
    <div class="BouncingsLoader">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  );
};

export default BouncingsLoader;

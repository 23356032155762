import React from "react";

export default function Search(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.678 14.9276C17.8883 13.2763 18.4306 11.2289 18.1964 9.19499C17.9621 7.16107 16.9685 5.29058 15.4145 3.95767C13.8604 2.62475 11.8603 1.9277 9.81437 2.00594C7.76844 2.08418 5.82747 2.93194 4.37972 4.37965C2.93197 5.82735 2.08418 7.76826 2.00594 9.81413C1.9277 11.86 2.62477 13.86 3.95773 15.414C5.29068 16.9681 7.16123 17.9616 9.19522 18.1959C11.2292 18.4301 13.2766 17.8878 14.928 16.6775C14.9665 16.7281 15.0083 16.7761 15.053 16.8212L19.8654 21.6335C20.0998 21.8681 20.4177 21.9999 20.7493 22C21.0809 22.0001 21.399 21.8685 21.6335 21.6341C21.8681 21.3998 21.9999 21.0818 22 20.7502C22.0001 20.4187 21.8685 20.1006 21.6341 19.8661L16.8217 15.0538C16.777 15.0086 16.729 14.9668 16.678 14.9288V14.9276ZM17.0005 10.1228C17.0005 11.4825 16.5973 12.8117 15.8418 13.9422C15.0864 15.0727 14.0127 15.9539 12.7565 16.4742C11.5003 16.9945 10.118 17.1307 8.78436 16.8654C7.45076 16.6001 6.22578 15.9454 5.26431 14.9839C4.30284 14.0225 3.64807 12.7976 3.3828 11.464C3.11753 10.1305 3.25368 8.74819 3.77402 7.49201C4.29437 6.23583 5.17554 5.16215 6.3061 4.40675C7.43667 3.65136 8.76586 3.24816 10.1256 3.24816C11.9489 3.24816 13.6976 3.97246 14.9869 5.26171C16.2761 6.55096 17.0005 8.29955 17.0005 10.1228Z"
        fill="#464b4b"
      />
    </svg>
  );
}

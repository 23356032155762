import React, { useState, useEffect } from "react";
import { useCustomContext } from "../../../context/Context";

import "./CreateEditEvent.scss";
import { EventsService } from "../../../services/events";

import { useNavigate, useParams } from "react-router-dom";
import SquareButton from "../../../components/Buttons/SquareButton";
import BouncingsLoader from "../../../components/Loaders/BouncingsLoader";
import LineButton from "../../../components/Buttons/LineButton";

const CreateEditEvent = () => {
  const { isAdmin } = useCustomContext();
  const params = useParams();
  const [eventResponse, setEventResponse] = useState({
    error: false,
    text: "",
    fullfilled: false,
    loading: false,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image_main: null,
    location: "",
    datetime: "",
    speakers_description: "",
    speakers: [],
    active: true,
    status: "upcoming",
    id: "",
  });

  const [previewImages, setPreviewImages] = useState({
    image_main: null,
    speakers: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === "image_main") {
      setFormData({
        ...formData,
        image_main: files ? files[0] : null,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prev) => ({ ...prev, image_main: reader.result }));
      };
      if (files[0]) reader.readAsDataURL(files[0]);
    }
    if (name.includes("avatar")) {
      setFormData((prevState) => ({
        ...prevState,
        speakers: prevState.speakers.map((speaker, index) =>
          index === parseInt(name.split("-")[name.split("-").length - 1])
            ? { ...speaker, avatar: files ? files[0] : null }
            : speaker
        ),
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prevState) => ({
          ...prevState,
          speakers: prevState.speakers.map((speaker, index) =>
            index === parseInt(name.split("-")[name.split("-").length - 1])
              ? { ...speaker, avatar: files ? reader.result : null }
              : speaker
          ),
        }));
      };
      if (files[0]) reader.readAsDataURL(files[0]);
    }
    if (name.includes("company")) {
      setFormData((prevState) => ({
        ...prevState,
        speakers: prevState.speakers.map((speaker, index) =>
          index === parseInt(name.split("-")[name.split("-").length - 1])
            ? { ...speaker, companyLogo: files ? files[0] : null }
            : speaker
        ),
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prevState) => ({
          ...prevState,
          speakers: prevState.speakers.map((speaker, index) =>
            index === parseInt(name.split("-")[name.split("-").length - 1])
              ? { ...speaker, companyLogo: files ? reader.result : null }
              : speaker
          ),
        }));
      };
      if (files[0]) reader.readAsDataURL(files[0]);
    }
  };

  const handleSpeakerChange = (index, field, value) => {
    const updatedSpeakers = [...formData.speakers];
    updatedSpeakers[index] = { ...updatedSpeakers[index], [field]: value };
    setFormData({
      ...formData,
      speakers: updatedSpeakers,
    });
  };

  const addSpeaker = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      speakers: [
        ...formData.speakers,
        {
          name: "",
          avatar: null,
          company: "",
          companyLogo: null,
          position: "",
          info: "",
        },
      ],
    });

    setPreviewImages({
      ...previewImages,
      speakers: [
        ...formData.speakers,
        {
          name: "",
          avatar: null,
          company: "",
          companyLogo: null,
          position: "",
          info: "",
        },
      ],
    })
  };

  const handleStatusChange = (e) => {
    setFormData((prev) => ({ ...prev, status: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEventResponse({
      error: false,
      text: "",
      fullfilled: false,
      loading: true,
    });

    const localDatetime = formData?.datetime
      ? new Date(formData?.datetime)
      : "";
    const utcDatetime = localDatetime
      ? new Date(
          localDatetime?.getTime() + localDatetime?.getTimezoneOffset() * 60000
        ).toISOString()
      : "";

    const data = new FormData();
    data.append(
      "eventData",
      JSON.stringify({
        title: formData.title,
        description: formData.description,
        datetime: utcDatetime,
        location: formData.location,
        image_main: formData.image_main,
        speakers_description: formData.speakers_description,
        speakers: formData.speakers.map((speaker) => ({
          name: speaker.name,
          company: speaker.company,
          position: speaker.position,
          info: speaker.info,
          avatar: speaker.avatar,
          companyLogo: speaker.companyLogo,
        })),
        status: formData.status,
        active: formData.active,
      })
    );

    if (formData.image_main) {
      data.append("image_main", formData.image_main);
    }

    formData.speakers.forEach((speaker, index) => {
      if (speaker.avatar) {
        data.append(`speakers-avatar-${index}`, speaker.avatar);
      }

      if (speaker.companyLogo) {
        data.append(`speakers-companyLogo-${index}`, speaker.companyLogo);
      }
    });

    try {
      const response = isEditMode
        ? await EventsService.editEvent(data, formData._id)
        : await EventsService.createEvent(data);
      setEventResponse({
        error: false,
        text: isEditMode ? "Event edited" : "Event created",
        fullfilled: true,
        loading: false,
      });

      if (isEditMode) {
        const { data } = await EventsService.getEvent(formData.title);
        setFormData({ ...data, datetime: formatDate(data.datetime) });
      }
    } catch (e) {
      console.error(e);
      setEventResponse({
        error: true,
        text: e?.response?.data?.message || "Error",
        fullfilled: true,
        loading: false,
      });
    }
  };

  const getEvent = async (id) => {
    try {
      const { data } = await EventsService.getEvent(id);
      setFormData({ ...data, datetime: formatDate(data.datetime) });
      setPreviewImages(data)
      setIsEditMode(true);
    } catch (e) {
      console.error(e);
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getEvent(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (!isAdmin) navigate("/");
  }, [isAdmin]);

  const formatDate = (date) => {
    try {
      if (!date) return "";
      const d = new Date(date);
      return d.toISOString().substring(0, 16); // Format to "2024-07-11T06:54"
    } catch (e) {
      console.error(e);
      return "";
    }
  };

  const removeSpeaker = (e, index) => {
    e.preventDefault();

    const updatedItems = formData.speakers.filter((_, i) => i !== index);
    const updatedImages = previewImages.speakers.filter((_, i) => i !== index)
    
    setFormData({
      ...formData, 
      speakers: updatedItems,
    });

    setPreviewImages({
      ...previewImages,
      speakers: updatedImages
    });
  }

  if (!isAdmin) return <></>;

  return (
    <div className="form-container">
      <LineButton
        text="Back to admin page"
        style={{ paddingLeft: "0", backgroundColor: "white" }}
        onClick={() => navigate("/admin")}
      />
      <h2>{isEditMode ? "Edit" : "Create"} Event</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title *</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Title"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description *</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            required
            rows="auto"
            minrows="5"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="image_main">Main Image</label>
          <input
            type="file"
            id="image_main"
            name="image_main"
            className="hidden"
            onChange={handleFileChange}
          />
          <label for="image_main" className="file-label">
            Select new file
          </label>
          {(previewImages.image_main || formData.image_main) && (
            <img
              className="form-preview-img"
              src={previewImages.image_main || formData.image_main}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="datetime">Date and Time</label>
          <input
            type="datetime-local"
            name="datetime"
            value={formData.datetime}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Location"
          />
        </div>
        <div className="form-group">
          <label htmlFor="speakers_description">Speakers Description</label>
          <textarea
            name="speakers_description"
            value={formData.speakers_description}
            onChange={handleChange}
            placeholder="Speakers Description"
            rows="auto"
            minrows="5"
          ></textarea>
        </div>
        <div className="speakers-section">
          <h3>Speakers</h3>
          {formData.speakers.map((speaker, index) => (
            <div key={index} className="speakers-card">
              <button className="speakers-card__close" onClick={(e) => removeSpeaker(e, index)}></button>
              <div className="form-group">
                <label htmlFor={`speaker-name-${index}`}>Name</label>
                <input
                  type="text"
                  value={speaker.name}
                  onChange={(e) =>
                    handleSpeakerChange(index, "name", e.target.value)
                  }
                  placeholder="Speaker Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor={`speaker-avatar-${index}`}>Avatar</label>
                <input
                  type="file"
                  name={`speaker-avatar-${index}`}
                  id={`speaker-avatar-${index}`}
                  className="hidden"
                  onChange={handleFileChange}
                />
                <label for={`speaker-avatar-${index}`} className="file-label">
                  Select new file
                </label>
                {(previewImages.speakers?.[index]?.avatar || speaker.avatar) && (
                  <img className="form-preview-img" src={previewImages.speakers?.[index]?.avatar || speaker.avatar} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor={`speaker-company-${index}`}>Company</label>
                <input
                  type="text"
                  value={speaker.company}
                  onChange={(e) =>
                    handleSpeakerChange(index, "company", e.target.value)
                  }
                  placeholder="Company"
                />
              </div>
              <div className="form-group">
                <label htmlFor={`speaker-company-${index}`}>Company Logo</label>
                <input
                  type="file"
                  name={`speaker-company-${index}`}
                  id={`speaker-company-${index}`}
                  className="hidden"
                  onChange={handleFileChange}
                />
                <label for={`speaker-company-${index}`} className="file-label">
                  Select new file
                </label>
                {(previewImages.speakers?.[index]?.companyLogo || speaker.companyLogo) && (
                  <img className="form-preview-img" src={previewImages.speakers?.[index]?.companyLogo || speaker.companyLogo} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor={`speaker-position-${index}`}>Position</label>
                <textarea
                  type="text"
                  value={speaker.position}
                  onChange={(e) =>
                    handleSpeakerChange(index, "position", e.target.value)
                  }
                  placeholder="Position"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor={`speaker-info-${index}`}>Info</label>
                <textarea
                  value={speaker.info}
                  onChange={(e) =>
                    handleSpeakerChange(index, "info", e.target.value)
                  }
                  placeholder="Info"
                  rows="auto"
                  minrows="5"
                ></textarea>
              </div>
            </div>
          ))}
          <SquareButton
            className="add-speaker-button"
            onClick={addSpeaker}
            text="Add speaker"
            type="button"
          />
        </div>
        <div className="status-inputs">
          <label>
            <input
              type="radio"
              value="upcoming"
              checked={formData.status === "upcoming"}
              onChange={handleStatusChange}
            />
            Upcoming
          </label>
          <label>
            <input
              type="radio"
              value="scheduled"
              checked={formData.status === "scheduled"}
              onChange={handleStatusChange}
            />
            Scheduled
          </label>
          <label>
            <input
              type="radio"
              value="past"
              checked={formData.status === "past"}
              onChange={handleStatusChange}
            />
            Past
          </label>
        </div>
        <div className="form-group form-footer__actions">
          <LineButton
            text="Back to admin page"
            bgColor="rgb(240, 240, 240)"
            textColor="black"
            onClick={() => navigate("/admin")}
          />
          <LineButton
            text="Back to events"
            bgColor="rgb(240, 240, 240)"
            textColor="black"
            onClick={() => navigate("/breakfasts")}
          />

          {eventResponse.loading ? (
            <SquareButton
              type="submit"
              text={`${isEditMode ? "Edit" : "Create"} event`}
              disabled
              style={{ opacity: '0.7', cursor: "wait" }}
            />
          ) : (
            <SquareButton
              type="submit"
              text={`${isEditMode ? "Edit" : "Create"} event`}
            />
          )}
        </div>

        {eventResponse.fullfilled && (
          <div
            className="form-group"
            style={{
              color: eventResponse.error ? "red" : "#333",
              textAlign: "center",
            }}
          >
            {eventResponse.text}
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateEditEvent;

import React from "react";

import "./buttons.scss";

const LineButton = ({
  text = "",
  textColor = "#171C8F",
  bgColor = "white",
  ...buttonProps
}) => {
  return (
    <button
      style={{
        color: textColor,
        backgroundColor: bgColor,
      }}
      className="line-btn"
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default LineButton;

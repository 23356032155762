import React, { useEffect, useState } from "react";
import "./TimeframeTable.scss";

import TimeframeContainer from "../timeframe_container/TimeframeContainer";

const TimeframeTable = ({ currentWidth }) => {
  const steps_data = {
    plan: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Onboarding",
        duration_weeks: 3,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
        },
        title: "Business analysis",
        duration_weeks: 4,
      },
      {
        styles: {
          backgroundColor: "#438474",
        },
        title: "Design",
        duration_weeks: 3,
        position: "flex-end",
      },
    ],
    develop: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Develop",
        duration_weeks: 2,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
          marginLeft: "10px",
        },
        title: "Review",
        duration_weeks: 1,
      },
      {
        styles: {
          backgroundColor: "#438474",
          marginLeft: "10px",
        },
        title: "Deploy",
        duration_weeks: 1,
      },
      {
        styles: {
          backgroundColor: "#6F4D63",
          marginLeft: "10px",
        },
        title: "Test",
        duration_weeks: 1,
      },
    ],
    scale: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Train",
        duration_weeks: 1.5,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
        },
        title: "Assess",
        duration_weeks: 2,
      },
      {
        styles: {
          backgroundColor: "#438474",
        },
        title: "Maintain",
        duration_weeks: 2.5,
      },
    ],
  };

  const [cellWidth, setCelltWidth] = useState(0);
  const [isRotated, setRotate] = useState({
    plan: false,
    develop: false,
    scale: false,
  });

  useEffect(() => {
    if (Object.values(isRotated).every((el) => el === false)) {
      let blocksToShow = document.querySelectorAll(
        Object.keys(isRotated).map((key) => `#${key}`)
      );
      blocksToShow.forEach((b) => {
        b.style = { ...b, transition: "opacity: 0.3s", opacity: "1" };
      });
      setTimeout(() => {
        blocksToShow.forEach((b) => (b.style.display = "block"));
      }, 300);
    } else {
      let blocksToHide = Object.keys(isRotated)
        .filter((el) => !isRotated[el])
        .map((key) => `#${key}`);
      let container = document.querySelectorAll(blocksToHide);
      container.forEach((b) => (b.style.opacity = "0"));
      setTimeout(() => {
        container.forEach((b) => (b.style.display = "none"));
      }, 300);

      let rotatedBlock =
        Object.keys(isRotated)[Object.values(isRotated).indexOf(true)];

      if (rotatedBlock !== "plan") {
        let findedBlock = document.getElementById(rotatedBlock);
        if (findedBlock) {
          let translateTo = document.getElementById("table-cell").offsetHeight;

          findedBlock.style.transform =
            rotatedBlock === "develop"
              ? `translateY(-${translateTo}px)`
              : `translateY(-${translateTo * 2 - 20}px)`;
        }
      }
    }
  }, [isRotated]);

  const changeRotate = (uniqueKey) => {
    setRotate({ ...isRotated, [uniqueKey]: !isRotated[uniqueKey] });
  };

  const onResize = () => {
    let cellWidth = document.getElementById("table-cell").offsetWidth;
    setCelltWidth(cellWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3} style={{ width: "21.42%" }}>
            Initiation phase
          </th>
          <th colSpan={3} style={{ width: "21.42%" }}>
            Design phase
          </th>
          <th colSpan={5} style={{ width: "35.74%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Development phase</div>
              <div className="header-sub-title">(sprint might be repeated)</div>
            </div>
          </th>
          <th colSpan={3} style={{ width: "21.42%" }}>
            Wrapping phase
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="table-cell">
            <div
              id="plan"
              className="arrow-container"
              onClick={() => {
                changeRotate("plan");
              }}
            >
              <TimeframeContainer
                currentWidth={currentWidth}
                isRotated={isRotated.plan}
                wrapperStyles={{
                  height: "166px",
                  border: "2px dashed #0071ce",
                  backgroundColor: "#f7fcff",
                  width: `calc(${cellWidth} * 5.8px)`,
                }}
                title={"Plan"}
                triangleStyles={{
                  width: " 116px",
                  height: "116px",
                  right: "-58px",
                  top: "24.5px",
                }}
                cellWidth={cellWidth}
                stepsData={steps_data.plan}
              />
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div
              id="develop"
              className="arrow-container"
              onClick={() => {
                changeRotate("develop");
              }}
            >
              <TimeframeContainer
                currentWidth={currentWidth}
                isRotated={isRotated.develop}
                wrapperStyles={{
                  height: "73px",
                  border: "2px dashed #0071ce",
                  backgroundColor: "#f7fcff",
                  width: `calc(${cellWidth} * 4.95px)`,
                }}
                stepsContainerStyles={{ flexDirection: "row" }}
                title={"Develop"}
                triangleStyles={{
                  width: " 49px",
                  height: "49px",
                  right: "-26px",
                  top: "11px",
                }}
                cellWidth={cellWidth}
                stepsData={steps_data.develop}
                clippedStep={"true"}
              />
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div
              id="scale"
              className="arrow-container arrow-container__scale"
              onClick={() => {
                changeRotate("scale");
              }}
            >
              <TimeframeContainer
                currentWidth={currentWidth}
                isRotated={isRotated.scale}
                wrapperStyles={{
                  height: "165px",
                  border: "2px dashed #0071ce",
                  backgroundColor: "#f7fcff",
                  width: `calc(${cellWidth} * 2.5px)`,
                }}
                title={"Scale"}
                triangleStyles={{
                  width: " 114px",
                  height: "114px",
                  right: "-58px",
                  top: "25px",
                }}
                cellWidth={cellWidth}
                stepsData={steps_data.scale}
              />
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default TimeframeTable;

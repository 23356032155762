const HEADER_LINKS = [
  {
    title: "About us",
    href: "/about-us",
    contentText:
      "Forvis Mazars Global Digital Services is a cross-functional team distributed globally applying our practical business experience to deliver valuable end-to-end solutions.",
  },
  {
    title: "How we work",
    href: "/how-we-work",
    contentText:
      "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.",
  },
  {
    title: "Our services",
    href: "/our-services",
    contentText:
      "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.",
  },
  // {
  //   title: 'Breakfasts',
  //   href: '/breakfasts',
  //   contentText:
  //     'We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.',
  // },
  {
    title: "Projects",
    href: "/projects",
    contentText:
      "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.",
  },
  {
    title: "Maintenance",
    href: "/maintenance",
    contentText:
      "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.",
  },
  {
    title: "Technology stack",
    href: "/technology-stack",
    contentText:
      "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose.",
  },
];

const PAGES_WITH_HIDEN_SUBHEADER = ["/breakfasts", "/admin", "/event-factory"];

const PAGES_WITH_FIXED_BACK_BTN = ["/breakfasts", "/admin", "/event-factory"];

export { HEADER_LINKS, PAGES_WITH_HIDEN_SUBHEADER, PAGES_WITH_FIXED_BACK_BTN };

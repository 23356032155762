import "./Anemon.scss";
import { useEffect, useState } from "react";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/anemon_1.json";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import { getLink } from "../../../services/utils";

// import anemon_header_desktop from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_header_desktop.png";
// import anemon_header_tablet from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_header_tablet.png";
// import anemon_header_mobile from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_header_mobile.png";

// import anemon_main_desktop from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_main_desktop.png";
// import anemon_main_tablet from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_main_tablet.png";
// import anemon_main_mobile from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_main_mobile.png";

// import anemon_works_desktop from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_works_desktop.png";
// import anemon_works_tablet from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_works_tablet.png";
// import anemon_works_mobile from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_works_mobile.png";

// import anemon_tech_colors_desktop from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_tech_colors_desktop.png";
// import anemon_tech_colors_tablet from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_tech_colors_tablet.png";
// import anemon_tech_colors_mobile from "../../../assets/projects/ProjectsPagesContent/Anemon/anemon_tech_colors_mobile.png";


const Anemon = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])

  return (
    <div className="anemon-container">
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "450px" : (currentWidth >= 600 ? "350px" : "250px")}
          animationHeight={currentWidth >= 900 ? "450px" : (currentWidth >= 600 ? "350px" : "250px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("anemon_header_desktop")
                  : currentWidth >= 600
                    ?  getLink("anemon_header_tablet")
                    :  getLink("anemon_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="main">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ?  getLink("anemon_main_desktop")
                  : currentWidth >= 600
                    ?  getLink("anemon_main_tablet")
                    :  getLink("anemon_main_mobile")
              }
              alt="main"
            />
          </div>
        </div>
        <div className="how-it-is-works">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ?  getLink("anemon_works_desktop")
                  : currentWidth >= 600
                    ?  getLink("anemon_works_tablet")
                    :  getLink("anemon_works_mobile")
              }
              alt="how-it-is-works"
            />
          </div>
        </div>
        <div className="technology-colors-typography">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ?  getLink("anemon_tech_colors_desktop")
                  : currentWidth >= 600
                    ?  getLink("anemon_tech_colors_tablet")
                    :  getLink("anemon_tech_colors_mobile")
              }
              alt="technology-colors-typography"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Anemon;

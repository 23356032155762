import React from "react";
import "./buttons.scss";

const ChevronButton = ({ isRotated }) => {
  return (
    <div
      className="chevron-btn"
      style={
        isRotated
          ? { transform: "rotate(180deg) translateX(-0%)" }
          : { transform: "rotate(0deg)" }
      }
    ></div>
  );
};

export default ChevronButton;

import React from "react";

export default function DataCentralization({ fill = "#434343", ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 6C3.25 4.458 4.48 3.264 6.008 2.5C7.58 1.716 9.7 1.25 12 1.25C14.3 1.25 16.42 1.715 17.992 2.5C19.52 3.264 20.75 4.458 20.75 6V18C20.75 19.542 19.52 20.735 17.992 21.5C16.42 22.285 14.3 22.75 12 22.75C9.7 22.75 7.58 22.285 6.008 21.5C4.48 20.734 3.25 19.541 3.25 18V6ZM4.75 6C4.75 5.333 5.31 4.526 6.679 3.842C8.002 3.181 9.882 2.75 12 2.75C14.118 2.75 15.998 3.18 17.321 3.842C18.69 4.526 19.25 5.332 19.25 6C19.25 6.667 18.69 7.474 17.321 8.158C15.998 8.819 14.118 9.25 12 9.25C9.882 9.25 8.002 8.82 6.679 8.158C5.31 7.474 4.75 6.668 4.75 6ZM4.75 18C4.75 18.667 5.31 19.474 6.679 20.158C8.002 20.819 9.882 21.25 12 21.25C14.118 21.25 15.998 20.82 17.321 20.158C18.689 19.474 19.25 18.668 19.25 18V14.707C18.873 15.007 18.446 15.272 17.992 15.499C16.42 16.285 14.3 16.75 12 16.75C9.7 16.75 7.58 16.285 6.008 15.5C5.56223 15.2801 5.14061 15.0144 4.75 14.707V18ZM19.25 8.707V12C19.25 12.667 18.69 13.474 17.321 14.158C15.998 14.819 14.118 15.25 12 15.25C9.882 15.25 8.002 14.82 6.679 14.158C5.31 13.474 4.75 12.668 4.75 12V8.707C5.127 9.007 5.554 9.272 6.008 9.499C7.58 10.285 9.7 10.75 12 10.75C14.3 10.75 16.42 10.285 17.992 9.5C18.4378 9.28015 18.8594 9.01437 19.25 8.707Z"
        fill={fill}
      />
    </svg>
  );
}

import React from 'react';

export default function AutomatedScripts(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3775 19.5L18.4425 21.435L19.5 22.5L22.5 19.5L19.5 16.5L18.435 17.5575L20.3775 19.5ZM14.1225 19.5L16.0575 17.565L15 16.5L12 19.5L15 22.5L16.065 21.4425L14.1225 19.5ZM19.275 6.97498L14.025 1.72498C13.9593 1.65138 13.8782 1.59309 13.7875 1.55421C13.6968 1.51534 13.5986 1.49682 13.5 1.49998H6C5.60254 1.50117 5.2217 1.65958 4.94065 1.94063C4.6596 2.22168 4.50119 2.60252 4.5 2.99998V12H6V2.99998H12V7.49998C12.0012 7.89744 12.1596 8.27828 12.4407 8.55933C12.7217 8.84037 13.1025 8.99879 13.5 8.99998H18V13.5H19.5V7.49998C19.5026 7.40141 19.4839 7.30344 19.4451 7.21281C19.4062 7.12218 19.3482 7.04104 19.275 6.97498ZM13.5 7.49998V3.29998L17.7 7.49998H13.5Z"
        fill="#434343"
      />
      <path
        d="M5.29998 20.4583C4.91321 20.4583 4.54227 20.3047 4.26878 20.0312C3.99529 19.7577 3.84165 19.3868 3.84165 19C3.84165 18.6132 3.99529 18.2423 4.26878 17.9688C4.54227 17.6953 4.91321 17.5416 5.29998 17.5416C5.68676 17.5416 6.05769 17.6953 6.33118 17.9688C6.60467 18.2423 6.75832 18.6132 6.75832 19C6.75832 19.3868 6.60467 19.7577 6.33118 20.0312C6.05769 20.3047 5.68676 20.4583 5.29998 20.4583ZM8.39582 19.4041C8.41248 19.2708 8.42498 19.1375 8.42498 19C8.42498 18.8625 8.41248 18.725 8.39582 18.5833L9.27498 17.9041C9.35415 17.8416 9.37498 17.7291 9.32498 17.6375L8.49165 16.1958C8.44165 16.1041 8.32915 16.0666 8.23748 16.1041L7.19998 16.5208C6.98331 16.3583 6.75832 16.2166 6.49582 16.1125L6.34165 15.0083C6.33318 14.9592 6.30762 14.9147 6.26949 14.8827C6.23136 14.8507 6.18312 14.8332 6.13332 14.8333H4.46665C4.36248 14.8333 4.27498 14.9083 4.25832 15.0083L4.10415 16.1125C3.84165 16.2166 3.61665 16.3583 3.39998 16.5208L2.36248 16.1041C2.27082 16.0666 2.15832 16.1041 2.10832 16.1958L1.27498 17.6375C1.22082 17.7291 1.24582 17.8416 1.32498 17.9041L2.20415 18.5833C2.18748 18.725 2.17498 18.8625 2.17498 19C2.17498 19.1375 2.18748 19.2708 2.20415 19.4041L1.32498 20.0958C1.24582 20.1583 1.22082 20.2708 1.27498 20.3625L2.10832 21.8041C2.15832 21.8958 2.27082 21.9291 2.36248 21.8958L3.39998 21.475C3.61665 21.6416 3.84165 21.7833 4.10415 21.8875L4.25832 22.9916C4.27498 23.0916 4.36248 23.1666 4.46665 23.1666H6.13332C6.23748 23.1666 6.32498 23.0916 6.34165 22.9916L6.49582 21.8875C6.75832 21.7791 6.98331 21.6416 7.19998 21.475L8.23748 21.8958C8.32915 21.9291 8.44165 21.8958 8.49165 21.8041L9.32498 20.3625C9.37498 20.2708 9.35415 20.1583 9.27498 20.0958L8.39582 19.4041Z"
        fill="#434343"
      />
    </svg>
  );
}

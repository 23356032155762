import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { WorkData, Notification } from "../data/HowWeWork";
import ArrowBlue from "../assets/home/arrow_blue_left.svg";

const Works = forwardRef(({ title = "How we work", noButton = false }, ref) => {
  const router = useNavigate();

  return (
    <div className="home-work" ref={ref}>
      <h2>{title}</h2>
      <div className="different-content">
        {WorkData.map((el, index) => (
          <div className="work-box" key={index}>
            {el.number && el.period && (
              <div className="work-box-row">
                <div className="work-number">{el.number}</div>
                <div className="arrow-period">
                  <img src={ArrowBlue} />
                  <div>{el.period}</div>
                </div>
              </div>
            )}
            {el.title && <div className="work-box-title">{el.title}</div>}
            {el.items && (
              <div className="work-box-items">
                <ul>
                  {el.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="card-content">
              {el.text.map((sentence, index) => (
                <React.Fragment key={index}>
                  {sentence}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
        <div className="body-text">{Notification}</div>
      </div>
      {!noButton && (
        <div className="button-blue" onClick={() => router("/how-we-work")}>
          See more
        </div>
      )}
    </div>
  );
});

export default Works;

import React, { useEffect, useState } from "react";
import "./Breakfasts.scss";

import HomeImg from "../../assets/home/Background.png";
import CalendarIcon from "../../assets/icons/Calendar.png";

import DefaultEventImage from "../../assets/breakfasts/ForvisMazars-Logo-Color-RGB.png";

import ArrowIcon from "../../assets/icons/chevron-right.png";
import SquareButton from "../../components/Buttons/SquareButton";
import { useNavigate } from "react-router-dom";

import Modal from "react-modal";
import TextField from "../../components/Inputs/TextField/TextField";
import LineButton from "../../components/Buttons/LineButton";
import { EventsService } from "../../services/events";
import { formatDate } from "../../helpers";
import { ResponsesService } from "../../services/Responses";
import BouncingsLoader from "../../components/Loaders/BouncingsLoader";
import { BreakfastPartners } from "../../data/BreakfastParrnets";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Breakfasts = () => {
  const [events, setEvents] = useState([]);
  const [areEventsLoading, setAreEventsLoading] = useState(false);

  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const openModal = (breakfast) => {
    setIsOpen(true);
    setParticipant((prev) => ({ ...prev, breakfast_id: breakfast._id }));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const navigateToDetails = (e, href) => {
    e.preventDefault();

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(href);
  };

  const [participant, setParticipant] = useState({
    email: "",
    company_name: "",
    name: "",
    location: "",
    job_title: "",
    breakfast_id: "",
    type: "participant",
  });

  const handleChange = (e, type) => {
    console.log("change", e.target.value, type);
    setParticipant((prev) => ({
      ...prev,
      [type]: e.target.value,
    }));
  };

  const getEvents = async () => {
    setAreEventsLoading(true);
    try {
      const { data } = await EventsService.getEvents();
      console.log("data", data);
      setEvents(data);
    } catch (e) {
      console.error(e);
    } finally {
      setAreEventsLoading(false);
    }
  };

  const participate = async (e) => {
    e.preventDefault();
    try {
      const response = await ResponsesService.create(participant);
      closeModal();
      setSuccessModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="breakfasts-page">
      <div
        className="body-header"
        style={{
          backgroundImage: `url( ${HomeImg})`,
        }}
      >
        <div className="body-content container">
          <div className="content-row">
            <div className="content-box">
              <h1>Breakfasts</h1>

              <SquareButton
                text="Our Projects"
                onClick={(e) => navigateToDetails(e, "/projects")}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="partners__wrapper">
          <div className="partners">
            <div className="partners__body">
              <h3 className="partners__title">Partners</h3>
              <div className="partners__imgs">
                <div className="partners__imgs-wrapper">
                  {BreakfastPartners.map((partner) => (
                    <img src={partner} alt="Partner" key={partner} />
                  ))}
                  {BreakfastPartners.map((partner) => (
                    <img src={partner} alt="Partner" key={partner + "-clone"} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {areEventsLoading ? (
          <BouncingsLoader />
        ) : (
          events.map((breakfast) => {
            return (
              <div className="breakfast-card__wrapper">
                <div className="breakfast-card" key={breakfast._id}>
                  <div className="breakfast-card__info">
                    <div className="breakfast-card__title">
                      {breakfast.title}
                    </div>
                    <div className="breakfast-card__description">
                      {breakfast.description}
                    </div>
                    <div className="breakfast-card__actions">
                      <div className="breakfast-card__date-wrapper">
                        <div className="breakfast-card__section-date">
                          <img src={CalendarIcon} alt="Date" />
                          <div className="breakfast-card__date">
                            <div className="breakfast-card__date-value">
                              {formatDate(breakfast.datetime)}
                            </div>
                            <div className="breakfast-card__date-title">
                              Date
                            </div>
                          </div>
                        </div>

                        <div className="breakfast-card__section-location">
                          <svg
                            className="breakfast-card__location"
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="60px"
                            height="60px"
                            viewBox="0 0 512.000000 60.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,290.000000) scale(0.100000,-0.100000)"
                              fill="#464b4b"
                              stroke="none"
                            >
                              <path
                                d="M2435 5114 c-16 -2 -68 -9 -115 -15 -561 -75 -1080 -487 -1290 -1026
-84 -214 -114 -375 -114 -618 -1 -244 40 -440 135 -656 51 -116 51 -117 378
-629 149 -233 267 -427 263 -431 -4 -4 -52 -14 -107 -24 -369 -65 -748 -198
-965 -341 -219 -143 -335 -350 -303 -539 34 -197 194 -370 468 -504 714 -351
2020 -434 3003 -191 426 105 760 273 904 453 195 246 140 533 -143 746 -205
155 -612 306 -1014 376 -55 10 -103 20 -107 24 -4 4 123 211 283 461 160 250
310 496 334 545 60 126 101 247 132 394 23 110 27 150 27 306 0 99 -6 216 -13
260 -112 713 -656 1268 -1361 1390 -84 15 -338 27 -395 19z m392 -323 c512
-105 913 -493 1042 -1006 77 -310 38 -656 -106 -932 -47 -89 -1193 -1893
-1203 -1893 -3 0 -219 336 -480 747 -261 410 -526 825 -588 922 -62 97 -129
210 -149 251 -276 578 -105 1278 406 1664 198 149 420 237 681 270 79 10 298
-3 397 -23z m-662 -3776 c203 -317 290 -446 314 -462 46 -31 116 -31 162 0 24
16 112 145 314 462 l281 440 39 -3 c22 -1 94 -12 160 -23 468 -79 818 -213
986 -379 117 -116 117 -186 1 -300 -218 -212 -717 -366 -1397 -432 -171 -16
-771 -16 -935 0 -365 37 -639 89 -885 168 -238 76 -401 161 -507 264 -109 106
-114 177 -20 281 134 149 451 287 848 368 164 34 321 60 344 57 8 0 141 -199
295 -441z"
                              />
                              <path
                                d="M2465 4204 c-11 -2 -45 -9 -75 -15 -217 -44 -429 -224 -522 -444 -41
-97 -53 -162 -53 -285 0 -123 12 -188 53 -285 93 -219 290 -387 522 -446 71
-18 269 -18 340 0 232 59 429 227 522 446 41 97 53 162 53 285 0 65 -6 139
-14 170 -68 269 -277 482 -546 557 -51 14 -243 26 -280 17z m176 -304 c235
-45 399 -275 361 -506 -32 -196 -180 -344 -376 -376 -289 -48 -556 219 -508
508 42 252 277 420 523 374z"
                              />
                            </g>
                          </svg>
                          <div className="breakfast-card__date">
                            <div className="breakfast-card__date-value">
                              {breakfast.location}
                            </div>
                            <div className="breakfast-card__date-title">
                              Location
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="breakfast-card__participate">
                      <div onClick={() => openModal(breakfast)}>
                        <img src={ArrowIcon} alt="Arrow" />I want to participate
                      </div>
                    </div>
                    <div className="breakfast-card__read-more">
                      <SquareButton
                        text=" Read more"
                        onClick={(e) =>
                          navigateToDetails(e, `/breakfasts/${breakfast.title}`)
                        }
                      />
                    </div>
                  </div>
                  <div className="breakfast-card__img">
                    <img
                      loading="lazy"
                      src={breakfast.image_main || DefaultEventImage}
                      alt="breakfast"
                      className=""
                    />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Participate"
      >
        <form
          onSubmit={participate}
          className="modal-body participate-modal-body"
        >
          <div className="modal-title">Participate in the event</div>
          <TextField
            handleChange={(e) => handleChange(e, "name")}
            inputText={participant.name}
            labelText="Name"
            type="text"
            id="name"
            required
          />
          <TextField
            handleChange={(e) => handleChange(e, "company_name")}
            inputText={participant.company_name}
            labelText="Company name"
            type="text"
            id="company_name"
            required
          />
          <TextField
            handleChange={(e) => handleChange(e, "email")}
            inputText={participant.email}
            labelText="Email"
            type="text"
            id="email"
            required
          />
          <TextField
            handleChange={(e) => handleChange(e, "location")}
            inputText={participant.location}
            labelText="Location"
            type="text"
            id="location"
            required
          />
          <TextField
            handleChange={(e) => handleChange(e, "job_title")}
            inputText={participant.job_title}
            labelText="Job title"
            type="text"
            id="job_title"
            required
          />

          <div className="modal-actions">
            <LineButton text="Cancel" onClick={closeModal} />
            <SquareButton type="submit" text="submit" />
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={successModal}
        style={customStyles}
        contentLabel="Participate"
      >
        <form
          className="modal-body participate-modal-body"
          onSubmit={participate}
        >
          <div className="modal-title">Thank you for your request</div>
          <div className="modal-actions">
            <SquareButton text="Close" onClick={() => setSuccessModal(false)} />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Breakfasts;

import "./Onpoint.scss";
import { useEffect, useState } from "react";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/onpoint_1.json";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import { getLink } from "../../../services/utils";

// import onpoint_header_computer from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_header_computer.png";
// import onpoint_header_tablet from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_header_tablet.png";
// import onpoint_header_mobile from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_header_mobile.png";

// import onpoint_features_computer from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_features_computer.png";
// import onpoint_features_tablet from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_features_tablet.png";
// import onpoint_features_mobile from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_features_mobile.png";

// import onpoint_technology_computer from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_technology_computer.png";
// import onpoint_technology_tablet from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_technology_tablet.png";
// import onpoint_technology_mobile from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_technology_mobile.png";

// import onpoint_colors_typoography_computer from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_colors_typoography_computer.png";
// import onpoint_colors_typoography_tablet from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_colors_typoography_tablet.png";
// import onpoint_colors_typoography_mobile from "../../../assets/projects/ProjectsPagesContent/OnPoint/onpoint_colors_typoography_mobile.png";


const Onpoint = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])

  return (
    <div className="onpoint-container">
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "400px" : (currentWidth >= 600 ? "300px" : "200px")}
          animationHeight={currentWidth >= 900 ? "400px" : (currentWidth >= 600 ? "300px" : "200px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("onpoint_header_computer")
                  : currentWidth >= 600
                    ? getLink("onpoint_header_tablet")
                    : getLink("onpoint_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="features">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("onpoint_features_computer")
                  : currentWidth >= 600
                    ? getLink("onpoint_features_tablet")
                    : getLink("onpoint_features_mobile")
              }
              alt="features"
            />
          </div>
        </div>
        <div className="colors-typography">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("onpoint_colors_typoography_computer")
                  : currentWidth >= 600
                    ? getLink("onpoint_colors_typoography_tablet")
                    : getLink("onpoint_colors_typoography_mobile")
              }
              alt="colors-typography"
            />
          </div>
        </div>
        <div className="technology">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("onpoint_technology_computer")
                  : currentWidth >= 600
                    ? getLink("onpoint_technology_tablet")
                    : getLink("onpoint_technology_mobile")
              }
              alt="technology"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onpoint;

import architecture from '../assets/our_services/architecture.svg';
import business_requirement from '../assets/our_services/business_requirement .svg';
import ci_cd_implementation from '../assets/our_services/ci_cd_implementation.svg';
import full_stack from '../assets/our_services/full_stack.svg';
import software_development from '../assets/our_services/software_development.svg';
import software_maintenance from '../assets/our_services/software_maintenance.svg';
import ux_ui_design from '../assets/our_services/ux_ui_design.svg';
import architecture_mobile from '../assets/our_services/architecture_review_mobile.svg';
import business_requirement_mobile from '../assets/our_services/business_requirement_mobile.svg';
import ci_cd_implementation_mobile from '../assets/our_services/ci_cd_implementation_mobile.svg';
import full_stack_mobile from '../assets/our_services/full_stack_mobile.svg';
import software_development_mobile from '../assets/our_services/software_development_mobile.svg';
import software_maintenance_mobile from '../assets/our_services/software_maintenance_mobile.svg';
import ux_ui_design_mobile from '../assets/our_services/ux_ui_design_mobile.svg';

export const ourServicesData = {
  'Architecture review': {
    title: 'Architecture review',
    services_list: {
      column_1: [
        'Gather and analyze business and technical requirements',
        'Propose possible tech stack',
        'Accompany client to tech stack decision',
        'Create Low level design',
      ],
    },
    decstop_img: architecture,
    mobile_img: architecture_mobile,
  },
  'Software development life cycle implementation': {
    title: 'Software development life cycle implementation',
    services_list: {
      column_1: [
        'Identify key players',
        'Propose solutions and process to accompany the project',
        'Accompany client to decide on process (goal-based, timeline-based, ROI-based, time-based)',
        'Formalize end-to-end process',
      ],
    },
    decstop_img: software_development,
    mobile_img: software_development_mobile,
  },
  'Business requirement gathering': {
    title: 'Business requirement gathering',
    services_list: {
      column_1: [
        `Understand the client's business, target audience, and goals`,
        'Conduct user research to identify user needs and preferences',
        'Document the business requirements',
      ],
    },
    decstop_img: business_requirement,
    mobile_img: business_requirement_mobile,
  },
  'UX/UI Design': {
    title: 'UX/UI Design',
    services_list: {
      column_1: [
        'Develop sets of visual designs which match branding and ensures a user-friendly experience',
        'Conduct usability testing to gather feedback',
      ],
    },

    decstop_img: ux_ui_design,
    mobile_img: ux_ui_design_mobile,
  },
  'Full stack development': {
    title: 'Full stack development',
    services_list: {
      column_1: [
        'Framework and languages selection (leveraging architecture review)',
        'Front-End Development',
        'Back-End Development',
        'Server-Side Development',
        'Database Management',
        'Deployment environment',
        'Developing security policies and procedures',
        'Documentation',
      ],
      column_2: [
        'Implementing security standards (firewalls, encryption or access controls to ressources)',
        'Limit access to application (SSO, user management, IP and Network management)',
        'Version Control/Git',
        'Connect to APIs and standard requests',
        'Unit testing',
        'End-to-end testing',
      ],
    },
    decstop_img: full_stack,
    mobile_img: full_stack_mobile,
  },
  'CI/CD implementation': {
    title: 'CI/CD implementation',
    services_list: {
      column_1: [
        'Containerize of application and services',
        'Orchestrate the appropriate environments',
        'Ensure appropriate management of the secrets or tokens',
        'Implement continuous integration and deployment',
        'Insert automated testings',
        'Document monitoring of the processes',
      ],
    },
    decstop_img: ci_cd_implementation,
    mobile_img: ci_cd_implementation_mobile,
  },
  'Software maintenance': {
    title: 'Software maintenance',
    services_list: {
      column_1: [
        'Corrective maintenance',
        'Perfective maintenance (feature enhancement and performance improvement)',
        'Preventive maintenance (security updates)',
        'Monitoring, analysis and reporting',
        'User support',
      ],
    },
    decstop_img: software_maintenance,
    mobile_img: software_maintenance_mobile,
  },
};

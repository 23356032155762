import React, { useEffect, useState } from "react";
import { getLink } from "../../services/utils";

import "./OurServices.scss";
import { ourServicesData } from "../../data/ourServicesData";
import { ServiceItem } from "../../components/ServiceItem";
import Platforms from "../../components/Platforms";

const OurServices = () => {
  // const [isPhone, setisPhone] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    // if (window.innerWidth <= 600) setisPhone(true);
    // if (window.innerWidth > 600) setisPhone(false);
    setCurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div>
      <div className="our-service-container">
        <div className="image-container">
          <img
            src={
              currentWidth >= 900
                ? getLink("our_services_desktop")
                : currentWidth >= 600
                ? getLink("our_services_mobile")
                : getLink("our_services_tablet")
            }
            alt="our-services"
          />
        </div>
      </div>

      {/* <Platforms /> */}
      {/* <div className="m-card" style={{ backgroundColor: "#0071CE" }}>
        <div className="m-card__content">
          <div className="container service-items">
            {Object.keys(ourServicesData).map((el, idx) => {
              return (
                <ServiceItem
                  item={ourServicesData[el]}
                  key={idx}
                  isMobile={isPhone}
                />
              );
            })}
          </div>
        </div>
      </div> */}
      {/* <div className="cards-container">
        {Object.keys(ourServicesData).map((el, idx) => {
          return (
            <ServiceItem
              item={ourServicesData[el]}
              key={idx}
              isMobile={isPhone}
            />
          );
        })}
      </div> */}
    </div>
  );
};

export default OurServices;

import Web from "../assets/services/web.svg"
import MobileApp from "../assets/services/mobile.svg"

export const ServicesData = [
  {
    title: "Web",
    image: Web,
  },
  {
    title: "Mobile app",
    image: MobileApp,
  },
]
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ns-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1100px) {
  .ns-wrapper {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/components/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EAJF;IAKI,0BAAA;EAEF;AACF","sourcesContent":[".ns-wrapper {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n\n  @media screen and (max-width: 1100px) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { useCustomContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { EventsService } from "../../services/events";

import CalendarIcon from "../../assets/icons/Calendar.png";
import SquareButton from "../../components/Buttons/SquareButton";
import BouncingsLoader from "../../components/Loaders/BouncingsLoader";

import "./Admin.scss";
import { format, parseISO, isValid } from "date-fns";
import { formatDate } from "../../helpers";
import Modal from "react-modal";
import LineButton from "../../components/Buttons/LineButton";
import { ResponsesService } from "../../services/Responses";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Admin = () => {
  const { isAdmin } = useCustomContext();
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [areEventsLoading, setAreEventsLoading] = useState(false);
  const [isConfirmModalOpen, setConfirmOpenModal] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    if (!isAdmin) navigate("/");
  }, [isAdmin]);

  const getEvents = async () => {
    setAreEventsLoading(true);

    try {
      const allEvents = true;
      const { data } = await EventsService.getEvents(allEvents);
      setEvents(data);
    } catch (e) {
      console.error(e);
    } finally {
      setAreEventsLoading(false);
    }
  };

  const navigateToDetails = (e, href) => {
    e.preventDefault();

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(href);
  };

  const havigateToCreateEventPage = () => {
    navigate("/event-factory");
  };

  const changeStatus = async (e, breakfast) => {
    const breakfastData = new FormData();
    breakfastData.append(
      "eventData",
      JSON.stringify({
        title: breakfast.title,
        description: breakfast.description,
        datetime: breakfast.datetime,
        location: breakfast.location,
        speakers_description: breakfast.speakers_description,
        speakers: breakfast.speakers.map((speaker) => ({
          name: speaker.name,
          company: speaker.company,
          position: speaker.position,
          avatar: speaker.avatar,
          companyLogo: speaker.companyLogo,
          info: speaker.info,
        })),
        status: breakfast.status,
        active: e.target.checked,
      })
    );

    if (breakfast.image_main) {
      breakfastData.append("image_main", breakfast.image_main);
    }

    await EventsService.editEvent(breakfastData, breakfast._id);
    const { data } = await EventsService.getEvents(true);
    setEvents(data);
  };

  const openConfirmModal = (type, event, responseId = null) => {
    setConfirmOpenModal(true);
    setConfirmModalType(type);
    setSelectedEvent(responseId || event);
  };

  const closeConfirmModal = () => {
    setConfirmOpenModal(false);
    setConfirmModalType("");
    setSelectedEvent({});
  };

  const confirmDelete = async (e) => {
    e.preventDefault();

    try {
      if (confirmModalType === "event") {
        await EventsService.deleteEvent(selectedEvent._id);
      }

      if (confirmModalType === "response") {
        await ResponsesService.delete(selectedEvent)
      }

      const { data } = await EventsService.getEvents(true);
      setEvents(data);
    } catch (e) {
      console.error(e);
    } finally {
      closeConfirmModal();
    }
  };

  const viewBreakfast = (e, href) => {
    e.preventDefault();

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(href);
  };

  useEffect(() => {
    getEvents();
  }, []);

  if (!isAdmin) return <></>;
  if (areEventsLoading) return <div className="admin-loader-wrapper"><BouncingsLoader /></div>;

  return (
    <div className="admin-wrapper">
      <div className="admin-header">
        <h2>Admin Panel</h2>
        <div className="admin-actions">
          <SquareButton
            text="Create event"
            onClick={havigateToCreateEventPage}
          />
          <LineButton 
            text="View all events"
            style={{ marginLeft: "20px" }}
            onClick={() => navigate("/breakfasts")}
          />
        </div>
      </div>

      {events.map((breakfast) => {
        return (
       <div className="breakfast-card__wrapper">
           <div className="breakfast-card" key={breakfast._id}>
            <div className="breakfast-card__info">
              <div className="breakfast-card__title">{breakfast.title}</div>
              <div className="breakfast-card__description">
                {breakfast.description}
              </div>
              <div className="breakfast-card__actions">
                <div className="breakfast-card__date-wrapper">
                  <div className="breakfast-card__section-date">
                    <img src={CalendarIcon} alt="Date" />
                    <div className="breakfast-card__date">
                      <div className="breakfast-card__date-value">
                        {formatDate(breakfast.datetime)}
                      </div>
                      <div className="breakfast-card__date-title">Date</div>
                    </div>
                  </div>

                  <div className="breakfast-card__section-location">
                    <svg
                      className="breakfast-card__location"
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60px"
                      height="60px"
                      viewBox="0 0 512.000000 60.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,290.000000) scale(0.100000,-0.100000)"
                        fill="#464b4b"
                        stroke="none"
                      >
                        <path
                          d="M2435 5114 c-16 -2 -68 -9 -115 -15 -561 -75 -1080 -487 -1290 -1026
-84 -214 -114 -375 -114 -618 -1 -244 40 -440 135 -656 51 -116 51 -117 378
-629 149 -233 267 -427 263 -431 -4 -4 -52 -14 -107 -24 -369 -65 -748 -198
-965 -341 -219 -143 -335 -350 -303 -539 34 -197 194 -370 468 -504 714 -351
2020 -434 3003 -191 426 105 760 273 904 453 195 246 140 533 -143 746 -205
155 -612 306 -1014 376 -55 10 -103 20 -107 24 -4 4 123 211 283 461 160 250
310 496 334 545 60 126 101 247 132 394 23 110 27 150 27 306 0 99 -6 216 -13
260 -112 713 -656 1268 -1361 1390 -84 15 -338 27 -395 19z m392 -323 c512
-105 913 -493 1042 -1006 77 -310 38 -656 -106 -932 -47 -89 -1193 -1893
-1203 -1893 -3 0 -219 336 -480 747 -261 410 -526 825 -588 922 -62 97 -129
210 -149 251 -276 578 -105 1278 406 1664 198 149 420 237 681 270 79 10 298
-3 397 -23z m-662 -3776 c203 -317 290 -446 314 -462 46 -31 116 -31 162 0 24
16 112 145 314 462 l281 440 39 -3 c22 -1 94 -12 160 -23 468 -79 818 -213
986 -379 117 -116 117 -186 1 -300 -218 -212 -717 -366 -1397 -432 -171 -16
-771 -16 -935 0 -365 37 -639 89 -885 168 -238 76 -401 161 -507 264 -109 106
-114 177 -20 281 134 149 451 287 848 368 164 34 321 60 344 57 8 0 141 -199
295 -441z"
                        />
                        <path
                          d="M2465 4204 c-11 -2 -45 -9 -75 -15 -217 -44 -429 -224 -522 -444 -41
-97 -53 -162 -53 -285 0 -123 12 -188 53 -285 93 -219 290 -387 522 -446 71
-18 269 -18 340 0 232 59 429 227 522 446 41 97 53 162 53 285 0 65 -6 139
-14 170 -68 269 -277 482 -546 557 -51 14 -243 26 -280 17z m176 -304 c235
-45 399 -275 361 -506 -32 -196 -180 -344 -376 -376 -289 -48 -556 219 -508
508 42 252 277 420 523 374z"
                        />
                      </g>
                    </svg>
                    <div className="breakfast-card__date">
                      <div className="breakfast-card__date-value">
                        {breakfast.location}
                      </div>
                      <div className="breakfast-card__date-title">Location</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="breakfast-card__read-more row">
                <SquareButton
                  text="Edit"
                  onClick={(e) =>
                    navigateToDetails(e, `/event-factory/${breakfast.title}`)
                  }
                />
                <LineButton
                  text="Delete"
                  style={{ marginLeft: "20px" }}
                  onClick={() => openConfirmModal("event", breakfast)}
                />
                <LineButton
                  text="View"
                  style={{ marginLeft: "20px" }}
                  onClick={(e) => viewBreakfast(e, `/breakfasts/${breakfast.title}`)}
                />
                <div className="switch-active">
                  <div>
                    {breakfast.active ? "Active" : "Inactive"}
                    <input
                      type="checkbox"
                      onChange={(e) => changeStatus(e, breakfast)}
                      role="switch"
                      class="toggle"
                      checked={breakfast.active}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="breakfast-card__img">
              {breakfast?.responses?.length ? (
                <div
                  className="response__wrapper"
                >
                  <div className="response__title">Responses:</div>
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Company Name</th>
                          <th>Email</th>
                          <th>Location</th>
                          <th>Job title</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {breakfast.responses.map((response, index) => (
                          <tr key={index}>
                            <td>{response.name}</td>
                            <td>{response.company_name}</td>
                            <td>{response.email}</td>
                            <td>{response.location}</td>
                            <td>{response.job_title}</td>
                            <td>{response.type}</td>
                            <td style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => openConfirmModal("response", breakfast, response._id)}>Delete</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div>No responses</div>
              )}
            </div>
          </div>
       </div>
        );
      })}

      <Modal
        isOpen={isConfirmModalOpen}
        style={customStyles}
        contentLabel="Confirm"
      >
        <form className="modal-body participate-modal-body">
          <div className="modal-title">
            Are you sure want to delete {confirmModalType}
          </div>
          <div className="modal-actions">
            <LineButton text="Cancel" onClick={closeConfirmModal} />
            <SquareButton text="Delete" onClick={confirmDelete} />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Admin;

import React from "react";

export default function ArrowRight(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16784 2.15817C6.2209 2.10804 6.28394 2.06826 6.35335 2.04111C6.42275 2.01397 6.49716 2 6.5723 2C6.64745 2 6.72185 2.01397 6.79126 2.04111C6.86066 2.06826 6.9237 2.10804 6.97677 2.15817L13.8322 8.61896C13.8854 8.66897 13.9276 8.72838 13.9564 8.79379C13.9852 8.8592 14 8.92932 14 9.00014C14 9.07096 13.9852 9.14108 13.9564 9.20649C13.9276 9.2719 13.8854 9.33132 13.8322 9.38133L6.97677 15.8421C6.8695 15.9432 6.72401 16 6.5723 16C6.4206 16 6.27511 15.9432 6.16784 15.8421C6.06056 15.741 6.0003 15.6039 6.0003 15.4609C6.0003 15.3179 6.06056 15.1808 6.16784 15.0797L12.6199 9.00014L6.16784 2.92055C6.11463 2.87053 6.07242 2.81112 6.04362 2.74571C6.01482 2.6803 6 2.61018 6 2.53936C6 2.46854 6.01482 2.39842 6.04362 2.33301C6.07242 2.2676 6.11463 2.20819 6.16784 2.15817Z"
        fill="#464b4b"

        // fill="#2D2C2C"
      />
    </svg>
  );
}

import ArrowLeft from "../../assets/buttons/arrow_back.png";
import { useNavigate } from "react-router-dom";

const ArrowBack = () => {
  const router = useNavigate();

  return (
    <img src={ArrowLeft}
      className="arrow-back"
      alt="arrow-back"
      onClick={() => router("/projects")}
    />
  )
}

export default ArrowBack;
import axios from "axios";
import { BASE_URL } from "../constants/config";

const USERS_ENDPOINTS = {
    login: `${BASE_URL}api/admins/login`,
    me: `${BASE_URL}api/admins/me`,
}

class Users {

     async login({ email, password }) {
        const response = await axios.post(USERS_ENDPOINTS.login, { email, password });
      
          return response.data;
    }

    async checkUser(token) {
        const response = await axios.get(USERS_ENDPOINTS.me, { token });
      
          return response.data;
    }


}

export const UsersService = new Users();
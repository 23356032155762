import React, { useEffect, useState } from "react";
import "./Login.scss";

import Modal from "react-modal";
import SquareButton from "../Buttons/SquareButton";

import CloseImg from "../../assets/icons/close.png";
import { useCustomContext } from "../../context/Context";
import { UsersService } from "../../services/admins";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};

const Login = () => {
  const { setIsLoginPageOpen, isLoginPageOpen, setIsAdmin, isAdmin } =
    useCustomContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("")
  const [password, setPassword] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const closeModal = () => {
    setIsLoginPageOpen(false);
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const login = async (e) => {
    setError("");
    e.preventDefault();
    setIsLoginLoading(true);
    try {
      const { token } = await UsersService.login({ email, password });
      localStorage.setItem("token", token);
      setIsAdmin(true);
      setIsLoginPageOpen(false);
      navigate("/admin");
      setError("");
    } catch (e) {
      console.error(e);
      setError("Invalid credentials");
      setIsAdmin(false);
    } finally {
      setIsLoginLoading(false);
    }
  };

  useEffect(() => {
    if (isLoginPageOpen && isAdmin) {
      navigate("/admin")
    }
  }, [isLoginPageOpen])

  if (isAdmin) return <></>

  return (
    <Modal
      isOpen={isLoginPageOpen}
      style={customStyles}
      className="login-modal"
    >
      <div class="login-page">
        <div class="form">
          <h3>Login</h3>
          <button
            tabIndex="-1"
            className="login-page__close-btn"
            onClick={closeModal}
          >
            <img src={CloseImg} alt="Close" />
          </button>
          <form class="login-form">
            <input
              type="text"
              placeholder="username"
              value={email}
              onChange={changeEmail}
            />
            <input
              type="password"
              placeholder="password"
              value={password}
              onChange={changePassword}
            />
            <SquareButton
              text="Login"
              onClick={login}
              disabled={isLoginLoading}
            />

            <div style={{ textAlign: "center", color: "red", marginTop: "20px" }}>{error && error}</div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default Login;

import React, { useCallback, useMemo, useState } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom';
import { ProjectsData } from '../data/Projects';
import { forwardRef } from 'react';
import ProjectFilter from './projects/ProjectFilter';
import ArrowRight from '../assets/icons/arrow-right';
// import { TechnologyScheme } from "./TechnologyScheme/TechnologyScheme";

const Projects = forwardRef(
  ({ title = 'Projects', noTitle = false, noButton = false }, ref) => {
    const router = useNavigate();

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [text, setText] = useState('');
    const [tags, setTags] = useState([]);
    const [contentLength, setContentLength] = useState(6);
    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const filteredData = useMemo(() => {
      const filtered = ProjectsData.filter((project) => {
        return !text
          ? ProjectsData
          : project.title?.toLowerCase().includes(text.trim().toLowerCase());
      }).filter((project) => {
        return tags.length === 0
          ? ProjectsData
          : tags.every((tag) => project.tags?.includes(tag));
      });

      return filtered;
    }, [text, tags]);

    const showLoadMore = useMemo(
      () => filteredData.length > contentLength,
      [filteredData, contentLength]
    );

    const list = useMemo(
      () => filteredData.filter((_, index) => index < contentLength),
      [filteredData, contentLength]
    );

    const onSearch = useCallback(
      (text) => {
        setText(text?.trim());
      },
      [setText]
    );

    const onTagsSelect = useCallback(
      (tags = []) => {
        setTags(tags);
      },
      [setTags]
    );

    const handleLoadMore = useCallback(() => {
      setContentLength(contentLength + 6);
    }, [contentLength]);

    return (
      <div
        className="projects"
        ref={ref}
        // style={{margin: "10vh 0"}}
      >
        <div className="home-projects-container container">
          {!noTitle && <h2>{title}</h2>}
          <ProjectFilter onSearch={onSearch} onTagsSelect={onTagsSelect} />
          <div className="home-projects-content projects-content">
            {list.map((el, index) => (
              <div className="project-card">
                <div
                  className="image-container slow-show "
                  key={index}
                  onClick={() => router(el.href)}
                  onMouseOver={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    className="front-image"
                    src={el.image}
                    alt="Project img"
                  />
                  {/* <div className="hover-image-wrapper" style={el.wrapper_styles}>
                  <img
                    className="back-image"
                    src={el.hoverImage}
                    alt="Project hover img"
                  />
                </div> */}
                </div>
                <h3 className="project-card__title">{el.title}</h3>
                <p className="project-card__text">{el.subTitle}</p>
                <p className="project-card__text">{el.text}</p>
                <div className="project-card__tags">
                  {el.tags.map((i) => (
                    <span key={i} className="project-card__tag">
                      {i}
                    </span>
                  ))}
                </div>

                <div className="project-card__read-more">
                  <button
                    onClick={() => router(el.href)}
                    className="project-card__read-more__btn"
                  >
                    <ArrowRight />
                    Read more
                  </button>
                </div>
              </div>
            ))}
          </div>
          {showLoadMore && (
            <div className="load-more">
              <button
                className="m-button m-button-white"
                onClick={handleLoadMore}
              >
                Load more
              </button>
            </div>
          )}
        </div>
        {/* <TechnologyScheme /> */}
      </div>
    );
  }
);

export default Projects;

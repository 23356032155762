import Lottie from "react-lottie";

const LottieLoader = ({animationData, animationWidth, animationHeight}) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="lottie-container">
      <Lottie options={defaultOptions} width={animationWidth} height={animationHeight} />
    </div>
  );
}

export default LottieLoader;
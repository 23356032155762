import { format, parseISO, isValid } from "date-fns";

export const formatDate = (dateString) => {
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) throw new Error("Invalid date");
    return format(date, "dd.MM.yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "XX.XX.XXXX";
  }
};

export const formatDateWithTime = (dateString) => {
  const parsedDate = parseISO(dateString);
  const formattedDate = format(parsedDate, 'dd.MM.yyyy HH:mm');

  return formattedDate
}

import ScemaDesktop from "../assets/how_we_work/spo_desktop.svg";
import ScemaTablet from "../assets/how_we_work/spo_tablet.svg";
import ScemaMobile from "../assets/how_we_work/spo_mobile.svg";

export const WorkData = [
  {
    number: 1,
    period: "1-2 weeks*",
    title: "Project scoping and agreement",
    items: ["Proposal and contract"],
    text: [
      "Client shares necessary information to lay out the scope of the project: goals, business requirements, timeframe.",
      "Proposal and quotation are discussed.",
      "Contract is signed."
    ],
  },
  {
    number: 2,
    period: "1-2 weeks*",
    title: "UX/UI design",
    items: ["User flows and personas", "UX/UI design"],
    text: [
      "Business analyst elaborates user flows and personas with the client.",
      "UX/UI designer integrates this work in proposals of interface for review.",
      "Client validates the chosen design option."
    ],
  },
  {
    number: 3,
    period: "1-2 weeks*",
    title: "MVP validation",
    items: ["Validated MVP", "Timeframe for dev and testing"],
    text: ["Application proposal is reviewed and adjusted with the client based on prioritization of features to define Minimum Viable Product."],
  },
  {
    number: 4,
    period: "4 weeks*",
    title: "Development and Testing",
    items: ["Developed features according to the validated MVP"],
    text: [
      "Application is developed in iteration of sprints corresponding to consistent groups of features.",
      "Each sprint ends with user acceptance testing and corresponding adjustments."
    ],
  },
  {
    number: 5,
    period: "1 weeks*",
    title: "Handover",
    items: ["User documentation and training", "Technical documentation and environment set up"],
    text: [
      "Key users are trained.",
      "Environment is set up."
    ],
  },
]

export const Notification = "* Time estimation based on the minimum MVP package. Timeframe to be discussed upon project scoping and agreement.";

export const SchemaDevice = {
  "desktop": ScemaDesktop,
  "tablet": ScemaTablet,
  "mobile": ScemaMobile,
};
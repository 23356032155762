// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  width: 100%;
  position: relative;
  margin: 0;
  font-family: var(--font-halyard);
}
.form-group .label-float {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  padding: 0 0.5rem;
  transition-property: top, font-size;
  transition-timing-function: ease-out;
  transition-duration: 200ms;
  cursor: text;
  color: #7B7B7B;
  font-size: 18px;
}
.form-group .label-float.activeLabel {
  top: -0.5rem;
  font-size: 12px;
  line-height: 1rem;
  transform: translate(0);
  padding: 0 0.5rem;
  color: #434343;
}
.form-group .form-input {
  border: none;
  border-bottom: 1px solid #d1d5db;
  width: 100%;
  padding: 0.5rem;
  outline: none;
  color: #434343;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/TextField/TextField.scss"],"names":[],"mappings":"AAMA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,gCAAA;AALF;AAOE;EACE,kBAAA;EACA,2BAAA;EACA,QAAA;EACA,iBAAA;EACA,mCAAA;EACA,oCAAA;EACA,0BAfO;EAgBP,YAAA;EACA,cArBI;EAsBJ,eAAA;AALJ;AAQM;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,iBAAA;EACA,cA5BC;AAsBT;AAWE;EACE,YAAA;EACA,gCAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,cAxCG;EAyCH,eAAA;AATJ","sourcesContent":["$light: #7B7B7B;\n$lighter: #d1d5db;\n$dark: #434343;\n$dark-1: #434343;\n$duration: 200ms;\n\n.form-group {\n  width: 100%;\n  position: relative;\n  margin: 0;\n  font-family:  var(--font-halyard);\n\n  .label-float {\n    position: absolute;\n    transform: translateY(-50%);\n    top: 50%;\n    padding: 0 0.5rem;\n    transition-property: top, font-size;\n    transition-timing-function: ease-out;\n    transition-duration: $duration;\n    cursor: text;\n    color: $light;\n    font-size: 18px;\n\n    &.activeLabel {\n      & {\n        top: -0.5rem;\n        font-size: 12px;\n        line-height: 1rem;\n        transform: translate(0);\n        padding: 0 0.5rem;\n        color: $dark-1;\n      }\n    }\n  }\n\n  .form-input {\n    border: none;\n    border-bottom: 1px solid $lighter;\n    width: 100%;\n    padding: 0.5rem;\n    outline: none;\n    color: $dark;\n    font-size: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useObserver } from "../hooks/useObserver";

const Context = createContext();

const CustomContextProvider = ({ children }) => {
  const [headerRef, setHeaderRef] = useState(null);
  const [anchorRefs, setAnchorRefs] = useState([]);
  const [prevBlock, setPrevBlock] = useState(null);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [nextBlock, setNextBlock] = useState(null);

  const [isLoginPageOpen, setIsLoginPageOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(() => !!localStorage.getItem("token"));

  const { isOnScreen, scrollDirection } = useObserver(currentBlock);

  // console.log("isOnScreen", isOnScreen)
  // console.log("scrollDirection", scrollDirection)
  // console.log("headerRef", headerRef)
  // console.log("anchorRefs", anchorRefs)

  return (
    <Context.Provider
      value={{
        headerRef,
        setHeaderRef,
        anchorRefs,
        setAnchorRefs,
        prevBlock,
        setPrevBlock,
        currentBlock,
        setCurrentBlock,
        nextBlock,
        setNextBlock,
        isOnScreen,
        scrollDirection,
        setIsLoginPageOpen,
        isLoginPageOpen,
        setIsAdmin,
        isAdmin,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => useContext(Context);

export default CustomContextProvider;

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Body from "../components/Body/Body";
import Login from "../components/Login/Login";

export default function LayOut({ children }) {
  const path = window.location.pathname;

  return (
    <div className="main-layout">
      <Header path={path} />
      <Body path={path} children={children} />
      {/* {children} */}
      <Footer />

      <Login />
    </div>
  );
}

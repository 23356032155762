import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import AboutUs from "../pages/AboutUs/AboutUs";
import HowWeWork from "../pages/HowWeWork/HowWeWork";
import OurServices from "../pages/OurServices/OurServices";
import Projects from "../pages/Projects/Projects";
import Maintenance from "../pages/Maintenance/Maintenance";
import TechnologyStack from "../pages/TechnologyStack/TechnologyStack";

import Panda from "../pages/ProjectsDescriptions/Panda/Panda";
import Anemon from "../pages/ProjectsDescriptions/Anemon/Anemon";
import Compass from "../pages/ProjectsDescriptions/Compass/Compass";
import Onpoint from "../pages/ProjectsDescriptions/Onpoint/Onpoint";
import DataMorph from "../pages/ProjectsDescriptions/DataMorph/DataMorph";
import Premier from "../pages/ProjectsDescriptions/Premier/Premier";
import UCertify from "../pages/ProjectsDescriptions/UCertify/UCertify";
import Astellas from "../pages/ProjectsDescriptions/Astellas/Astellas";

import LayOut from "../pages/LayOut";
import Claims from "../pages/ProjectsDescriptions/Claims/Claims";
import Flyer from "../pages/ProjectsDescriptions/Flyer/Flyer";
import Engage from "../pages/ProjectsDescriptions/Engage/Engage";
import Breakfasts from "../pages/Breakfasts/Breakfasts";
import BreakfastsDetails from "../pages/Breakfasts/BreakfastsDetails";
import Admin from "../pages/Admin/Admin";
import CreateEditEvent from "../pages/Breakfasts/components/CreateEditEvent";
import FinCrimes from "../pages/ProjectsDescriptions/FinCrimes/FinCrimes";
import UseCase from "../pages/ProjectsDescriptions/UseCase/UseCase";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LayOut>
        <HomePage />
      </LayOut>
    ),
  },
  {
    path: "/about-us",
    element: (
      <LayOut>
        <AboutUs />
      </LayOut>
    ),
  },
  {
    path: "/how-we-work",
    element: (
      <LayOut>
        <HowWeWork />
      </LayOut>
    ),
  },
  {
    path: "/our-services",
    element: (
      <LayOut>
        <OurServices />
      </LayOut>
    ),
  },
  {
    path: "/breakfasts",
    element: (
      <LayOut>
        <Breakfasts />
      </LayOut>
    ),
  },
  {
    path: "/breakfasts/:id",
    element: (
      <LayOut>
        <BreakfastsDetails />
      </LayOut>
    ),
  },
  {
    path: "/projects",
    element: (
      <LayOut>
        <Projects />
      </LayOut>
    ),
  },
  {
    path: "/maintenance",
    element: (
      <LayOut>
        <Maintenance />
      </LayOut>
    ),
  },
  {
    path: "/technology-stack",
    element: (
      <LayOut>
        <TechnologyStack />
      </LayOut>
    ),
  },
  //------------------------------------------projects
  {
    path: "/projects/insurance-registration",
    element: (
      <LayOut>
        <Panda />
      </LayOut>
    ),
  },
  {
    path: "/projects/training_platform",
    element: (
      <LayOut>
        <Anemon />
      </LayOut>
    ),
  },
  {
    path: "/projects/management_solution",
    element: (
      <LayOut>
        <Compass />
      </LayOut>
    ),
  },
  {
    path: "/projects/opportunity_generator",
    element: (
      <LayOut>
        <Onpoint />
      </LayOut>
    ),
  },
  {
    path: "/projects/datamorph",
    element: (
      <LayOut>
        <DataMorph />
      </LayOut>
    ),
  },
  {
    path: "/projects/navigation_system",
    element: (
      <LayOut>
        <Premier />
      </LayOut>
    ),
  },
  {
    path: "/projects/claims",
    element: (
      <LayOut>
        <Claims />
      </LayOut>
    ),
  },
  {
    path: "/projects/learning_platform",
    element: (
      <LayOut>
        <UCertify />
      </LayOut>
    ),
  },
  {
    path: "/projects/flyer",
    element: (
      <LayOut>
        <Flyer />
      </LayOut>
    ),
  },
  {
    path: "/projects/engage",
    element: (
      <LayOut>
        <Engage />
      </LayOut>
    ),
  },
  {
    path: "/projects/astellas",
    element: (
      <LayOut>
        <Astellas />
      </LayOut>
    ),
  },
  {
    path: "/projects/fin_crimes",
    element: (
      <LayOut>
        <FinCrimes />
      </LayOut>
    ),
  },
  {
    path: "/projects/use_case",
    element: (
      <LayOut>
        <UseCase />
      </LayOut>
    ),
  },
  //
  {
    path: "/admin",
    element: (
      <LayOut>
        <Admin />
      </LayOut>
    ),
  },
  {
    path: "/event-factory",
    element: (
      <LayOut>
        <CreateEditEvent />
      </LayOut>
    ),
  },
  {
    path: "/event-factory/:id",
    element: (
      <LayOut>
        <CreateEditEvent />
      </LayOut>
    ),
  },
]);

export default router;

import React from "react";

export default function Pipeline({
  fill = "none",
  stroke = "#434343",
  ...props
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 12C8.5 6.47715 7.15685 2 5.5 2C3.84315 2 2.5 6.47715 2.5 12C2.5 17.5228 3.84315 22 5.5 22C7.15685 22 8.5 17.5228 8.5 12Z"
        stroke={stroke}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 17.9535C8.52381 19.821 9.28952 21 10.1429 21C11.721 21 13 16.9707 13 12C13 7.0293 11.721 3 10.1429 3C9.28952 3 8.52381 4.17855 8 6.0465"
        stroke={stroke}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 17.292C13.0238 18.952 13.7895 20 14.6429 20C16.221 20 17.5 16.4184 17.5 12C17.5 7.5816 16.221 4 14.6429 4C13.7895 4 13.0238 5.0476 12.5 6.708"
        stroke={stroke}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 16.6305C17.5238 18.083 18.2895 19 19.1429 19C20.721 19 22 15.8661 22 12C22 8.1339 20.721 5 19.1429 5C18.2895 5 17.5238 5.91665 17 7.3695C17.4448 8.6036 17.7143 10.2248 17.7143 12C17.7143 13.7752 17.4448 15.396 17 16.6305Z"
        stroke={stroke}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

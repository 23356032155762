import React, { useEffect, useRef, useState } from "react";
import "./Body.scss";
import ArrowDownCircle from "../../assets/header/arrow_down_circle.svg";
import {
  HEADER_LINKS,
  PAGES_WITH_FIXED_BACK_BTN,
  PAGES_WITH_HIDEN_SUBHEADER,
} from "../../constants/AppConstants";
import { HeaderData } from "../../data/HeaderData";
import { useCustomContext } from "../../context/Context";
import { ProjectsData } from "../../data/Projects";
import AboutUsImg from "../../assets/main-backgrounds/about-us.png";
import HowWeWorkImg from "../../assets/main-backgrounds/how-we-work.png";
import ProjectsImg from "../../assets/main-backgrounds/projects.png";
import ServicesImg from "../../assets/main-backgrounds/our-services.png";
import MaintenanceImg from "../../assets/main-backgrounds/maintenance.png";
import TechStack from "../../assets/main-backgrounds/tech-stack.png";
import HomeImg from "../../assets/home/Background.png";
import FixedBackButton from "../Buttons/FixedBackButton";

const MAIN_BACKGROUND = {
  "/": HomeImg,
  "/about-us": AboutUsImg,
  "/how-we-work": HowWeWorkImg,
  "/our-services": ServicesImg,
  "/projects": ProjectsImg,
  "/maintenance": MaintenanceImg,
  "/technology-stack": TechStack,
};

const Body = (props) => {
  const { path } = props;
  const { setHeaderRef, setCurrentBlock } = useCustomContext();
  const headerRef = useRef(null);

  const image = MAIN_BACKGROUND[path];

  const [currentWidth, setCurrentWidth] = useState(null);
  let [headerData, setProjectHeaderData] = useState(null);

  const scrollToBody = () => {
    const screenHeight = window.innerHeight;
    window.scrollTo({
      top: screenHeight + 1,
      behavior: "smooth",
    });
  };

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    setHeaderRef(headerRef);
    setCurrentBlock(headerRef);
  }, []);

  useEffect(() => {
    let findedElement = ProjectsData.find((obj) => obj.href === path);
    if (findedElement) {
      setProjectHeaderData({ ...findedElement.headerData });
    }
    //commented logic for slow show title
    // const LtargetPageabelsList = document.getElementsByClassName("content-row");
    const targetPage = document.getElementsByClassName("body");

    setTimeout(() => {
      targetPage?.[0].classList.add("slow-show");
      //commented logic for slow show title
      // for (let i = 0; i < LabelsList.length; i++) {
      //   const targetLabel = LabelsList[i];
      //   targetLabel.classList.add("slow-show");
      // }
    });

    return () => {
      targetPage?.[0].classList.remove("slow-show");
      //commented logic for slow show title
      // for (let i = 0; i < LabelsList.length; i++) {
      //   const targetLabel = LabelsList[i];
      //   targetLabel.classList.remove("slow-show");
      // }
    };
  }, [props.path]);

  const currentPath = path.split("/");
  const isBreakfastsDetails =
    (currentPath.includes("breakfasts") ||
      currentPath.includes("event-factory")) &&
    currentPath.length >= 3;

  const currentRoute = "/" + currentPath[1];

  return (
    <div className="body">
      {PAGES_WITH_FIXED_BACK_BTN.includes(currentRoute) && <FixedBackButton />}
      <div ref={headerRef}></div>
      {
        //Check if the project information has been clicked
        !ProjectsData.map((el) => el.href).includes(path) && (
          <div>
            {!PAGES_WITH_HIDEN_SUBHEADER.includes(
              isBreakfastsDetails ? currentRoute : path
            ) && (
              <div
                className="body-header"
                style={{
                  backgroundImage: `url( ${image})`,
                  ...(path === "/" ? {} : { height: "50vh" }),
                }}
              >
                <div className={"body-content container"}>
                  <div className="content-row">
                    <div
                      className="content-box"
                      // style={path === "/projects" ? { alignItems: "center" } : {}}
                    >
                      <h1>
                        {path !== "/"
                          ? HEADER_LINKS.filter((el) => el.href === path)[0]
                              ?.title || HEADER_LINKS.includes(path)[0]?.title
                          : "Forvis Mazars’ Digital Services"}
                      </h1>
                      <div
                        className="content-text"
                        style={{ fontWeight: "500" }}
                      >
                        {path === "/"
                          ? `We provide full-stack development services for a variety
                        of platforms and deliver a product fit for your purpose.`
                          : null}
                      </div>
                      {/* <div
                      className="content-text"
                      style={
                        path === "/projects" ? { textAlign: "center" } : {}
                      }
                    >
                      {path !== "/"
                        ? HEADER_LINKS.filter((el) => el.href === path)[0]
                            .contentText
                        : "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose."}
                    </div> */}
                    </div>
                    {/* {path !== "/projects" && (
                    <div className="content-image">
                      <img src={HeaderData[path]} alt="device" />
                    </div>
                  )} */}
                  </div>
                </div>
                {/* {!["/how-we-work", "/our-services", "/projects"].includes(
                path
              ) && (
                <div className="body-arrow-down" onClick={scrollToBody}>
                  <img src={ArrowDownCircle} alt="Arrow down" />
                </div>
              )} */}
              </div>
            )}
            <div>{props.children}</div>
          </div>
        )
      }
      {
        ProjectsData.map((el) => el.href).includes(path) && headerData && (
          <div>{props.children}</div>
        )
        // <div className="project-header-wrapper">
        //   <div className={`${headerData.class}`}>
        //     <div className="title-description">
        //       <h1>{headerData.title}</h1>
        //       <div className="content-text">{headerData.description}</div>
        //     </div>
        //     <div className="image-container">
        //       <img
        //         src={
        //           currentWidth >= 1200
        //             ? headerData.image.decstop
        //             : currentWidth >= 600
        //             ? headerData.image.tablet
        //             : headerData.image.mobile
        //         }
        //         alt="project img"
        //       />
        //     </div>
        //   </div>
        // </div>
      }
      <div></div>
    </div>
  );
};

export default Body;

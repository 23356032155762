import './FinCrimes.scss';
import { useEffect, useState } from 'react';
import LottieLoader from '../../../components/Loader/LottieLoader';
import animationDataTheme from '../../../assets/animation/fin_crimes.json';
import ArrowBack from '../../../components/Buttons/ArrowBack';
import { getLink } from '../../../services/utils';

const FinCrimes = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className="fin-crimes-container slow-show">
      <div style={{ display: loading ? 'block' : 'none' }}>
        <LottieLoader
          animationData={animationDataTheme}
          animationWidth={
            currentWidth >= 900
              ? '500px'
              : currentWidth >= 600
                ? '500px'
                : '300px'
          }
          animationHeight={
            currentWidth >= 900
              ? '550px'
              : currentWidth >= 600
                ? '550px'
                : '350px'
          }
        />
      </div>
      <div
        style={{ display: !loading ? 'block' : 'none' }}
        className={!loading ? 'slow-show' : ''}
      >
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("fin_crimes_header_desktop")
                  : currentWidth >= 600
                    ? getLink("fin_crimes_header_tablet")
                    : getLink("fin_crimes_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="features">
          <div className="image-container">
            <img
              onLoad={() => setLoading(false)}
              src={
                currentWidth >= 900
                  ? getLink("fin_crimes_features_desktop")
                  : currentWidth >= 600
                    ? getLink("fin_crimes_features_tablet")
                    : getLink("fin_crimes_features_mobile")
              }
              alt="features"
            />
          </div>
        </div>
        <div className="colors">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("fin_crimes_colors_desktop")
                  : currentWidth >= 600
                    ? getLink("fin_crimes_colors_tablet")
                    : getLink("fin_crimes_colors_mobile")
              }
              alt="colors"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinCrimes;

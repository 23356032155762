import React, { useEffect, useRef, useState } from "react";
import "./HomePage.scss";
import { useNavigate } from "react-router-dom";
import { BrandsData } from "../../data/Brands";
import Different from "../../components/Different";
import Works from "../../components/Works";
import Platforms from "../../components/Platforms";
import Projects from "../../components/Projects";
import ArrowUp from "../../assets/home/arrow_blue_up.svg";
import ArrowDown from "../../assets/home/arrow_blue_down.svg";
import { useCustomContext } from "../../context/Context";
import HomeWidget from "../../components/Home/HomeWidget";
import { HOME_WIDGETS } from "../../components/Home/widgets";

const HomePage = () => {
  const [isPC, setIsPC] = useState(false);

  const onResize = () => {
    if (window.innerWidth >= 1200) setIsPC(true);
    if (window.innerWidth < 1200) setIsPC(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const router = useNavigate();

  const differentRef = useRef(null);
  const worksRef = useRef(null);
  const servicesRef = useRef(null);
  const projectsRef = useRef(null);
  const brandsRef = useRef(null);

  const refs = [differentRef, worksRef, servicesRef, projectsRef, brandsRef];

  const {
    headerRef,
    anchorRefs,
    setAnchorRefs,
    prevBlock,
    setPrevBlock,
    currentBlock,
    setCurrentBlock,
    nextBlock,
    setNextBlock,
    isOnScreen,
    scrollDirection,
  } = useCustomContext();
  // console.log("anchorRefs", anchorRefs)
  // console.log("currentBlock", currentBlock)
  // console.log("headerRef", headerRef)
  // console.log("isOnScreen", isOnScreen)
  // console.log("prevBlock", prevBlock)
  // console.log("nextBlock", nextBlock)

  const scrollToSection = (ref, block) => {
    // console.log("scrollToSection > ref", ref)
    if (ref) {
      // ref.current.scrollIntoView({ behavior: "smooth", block });
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  // const scrollToSection = (ref) => {
  //   ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

  //   const handleScroll = () => {
  //     // if (window.scrollY >= ref.current.offsetTop) {
  //       window.removeEventListener('scroll', handleScroll);
  //       // window.scrollBy(0, 50);
  //     // }
  //   };

  //   // window.addEventListener('scroll', handleScroll);
  // };

  useEffect(() => {
    setAnchorRefs(refs);
    setNextBlock(refs[0]);
  }, []);

  useEffect(() => {
    if (anchorRefs.length && !isOnScreen) {
      const foundIndex = anchorRefs.findIndex((el) => {
        // console.log("find > el", el.current, currentBlock.current);
        return el.current == currentBlock.current;
      });
      // console.log("foundIndex > ", foundIndex);
      // setCurrentBlock(foundIndex ? anchorRefs[foundIndex] : headerRef);
      if (scrollDirection == "up") {
        const isValid = anchorRefs[foundIndex - 2] !== undefined;
        setPrevBlock(
          foundIndex !== -1 && isValid
            ? anchorRefs[foundIndex - 2]
            : // : isValid
              // ? anchorRefs[1]
              headerRef
        );
        setCurrentBlock(prevBlock);
        setNextBlock(currentBlock);
      }
      if (scrollDirection == "down") {
        setPrevBlock(currentBlock);
        setCurrentBlock(nextBlock);
        setNextBlock(
          foundIndex !== -1 ? anchorRefs[foundIndex + 2] : anchorRefs[1]
        );
      }
    }
  }, [isOnScreen]);

  return (
    <div className="home-container">
      <div className="home-main">
        {HOME_WIDGETS.map((i) => (
          <HomeWidget
            key={i.title}
            title={i.title}
            position={i.position}
            style={{ backgroundColor: i.color }}
            content={i.content}
            image={i.image}
            href={i.href}
          />
        ))}
        {/* <Different ref={differentRef} />
        <Works ref={worksRef} /> */}
      </div>
      {/* <div className="home-services" ref={servicesRef}>
        <div className="home-services-container">
          <h2 style={{ color: "#FFF" }}>Our services</h2>
          <Platforms />
          <div className="button" onClick={() => router("/our-services")}>
            See more
          </div>
        </div>
      </div> */}
      {/* <Projects ref={projectsRef} /> */}
      <div className="home-brands" ref={brandsRef}>
        <div className="home-brands__wrapper">
          <h3 className="home-brands__title">Partners</h3>
          <div className="home-brands__content">
            <div className="home-brands-container">
              {isPC
                ? BrandsData.map((el, index) => (
                    <div className="img-wrapper" key={index}>
                      <img src={el.image} alt="client logo" />
                    </div>
                  ))
                : [...BrandsData, ...BrandsData].map((el, index) => (
                    <div className="img-wrapper" key={index}>
                      <img src={el.image} alt="client logo" />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* {currentBlock?.current !== headerRef?.current && (
        <div className="navi-buttons">
          <img
            src={ArrowUp}
            alt="arrow up"
            onClick={() => scrollToSection(prevBlock, "start")}
          />
          <img
            src={ArrowDown}
            alt="arroe down"
            onClick={() => scrollToSection(nextBlock, "start")}
          />
        </div>
      )} */}
    </div>
  );
};

export default HomePage;

import "./Compass.scss";
import { useEffect, useState } from "react";
import LottieLoader from "../../../components/Loader/LottieLoader";
import animationData from "../../../assets/animation/compass_2.json";
import ArrowBack from "../../../components/Buttons/ArrowBack";
import { getLink } from "../../../services/utils";

// import compas_header_computer from "../../../assets/projects/ProjectsPagesContent/Compass/compas_header_computer.png";
// import compas_header_tablet from "../../../assets/projects/ProjectsPagesContent/Compass/compas_header_tablet.png";
// import compas_header_mobile from "../../../assets/projects/ProjectsPagesContent/Compass/compas_header_mobile.png";

// import compas_features_computer from "../../../assets/projects/ProjectsPagesContent/Compass/compas_features_computer.png";
// import compas_features_tablet from "../../../assets/projects/ProjectsPagesContent/Compass/compas_features_tablet.png";
// import compas_features_mobile from "../../../assets/projects/ProjectsPagesContent/Compass/compas_features_mobile.png";

// import compas_iconography_computer from "../../../assets/projects/ProjectsPagesContent/Compass/compas_iconography_computer.png";
// import compas_iconography_tablet from "../../../assets/projects/ProjectsPagesContent/Compass/compas_iconography_tablet.png";
// import compas_iconography_mobile from "../../../assets/projects/ProjectsPagesContent/Compass/compas_iconography_mobile.png";

// import compas_other_computer from "../../../assets/projects/ProjectsPagesContent/Compass/compas_other_computer.png";
// import compas_other_tablet from "../../../assets/projects/ProjectsPagesContent/Compass/compas_other_tablet.png";
// import compas_other_mobile from "../../../assets/projects/ProjectsPagesContent/Compass/compas_other_mobile.png";


const Compass = () => {
  const [loading, setLoading] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 5000);
  }, [])

  return (
    <div className="compass-container">
      <div style={{ display: loading ? "block" : "none" }}>
        <LottieLoader
          animationData={animationData}
          animationWidth={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
          animationHeight={currentWidth >= 900 ? "350px" : (currentWidth >= 600 ? "300px" : "200px")}
        />
      </div>
      <div style={{ display: !loading ? "block" : "none" }} className={!loading ? "slow-show" : ""}>
        <ArrowBack />
        <div className="header-block">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("compas_header_computer")
                  : currentWidth >= 600
                    ? getLink("compas_header_tablet")
                    : getLink("compas_header_mobile")
              }
              alt="header-block"
            />
          </div>
        </div>
        <div className="features">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("compas_features_computer")
                  : currentWidth >= 600
                    ? getLink("compas_features_tablet")
                    : getLink("compas_features_mobile")
              }
              alt="features"
            />
          </div>
        </div>
        <div className="iconography">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("compas_iconography_computer")
                  : currentWidth >= 600
                    ? getLink("compas_iconography_tablet")
                    : getLink("compas_iconography_mobile")
              }
              alt="iconography"
            />
          </div>
        </div>
        <div className="other">
          <div className="image-container">
            <img
              src={
                currentWidth >= 900
                  ? getLink("compas_other_computer")
                  : currentWidth >= 600
                    ? getLink("compas_other_tablet")
                    : getLink("compas_other_mobile")
              }
              alt="other"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compass;

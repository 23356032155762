import { getLink } from '../services/utils';

// import Panda from "../assets/projects/panda.png";
// import Datamorph from "../assets/projects/datamorph.png";
// import Compass from "../assets/projects/compass.png";
// import Anemon from "../assets/projects/anemon.png";
// import Onpoint from "../assets/projects/onpoint.png";
// import Premier from "../assets/projects/premier.png";

// import PandaHoverImg from "../assets/projects/panda_back.png";
// import DatamorphHoverImg from "../assets/projects/datamorph_back.png";
// import CompassHoverImg from "../assets/projects/compass_back.png";
// import AnemonHoverImg from "../assets/projects/anemon_back.png";
// import OnpointHoverImg from "../assets/projects/onpoint_back.png";
// import PremierHoverImg from "../assets/projects/premier_back.png";

export const ProjectsData = [
  {
    // image: Panda,
    image: getLink('thumb_panda'),
    // hoverImage: PandaHoverImg,
    hoverImage: getLink('thumb_panda_back'),
    href: '/projects/insurance-registration',
    wrapper_styles: {
      height: '123%',
      width: '122%',
      top: '-17%',
      left: '-14%',
    },
    title: 'Cloud end to end insurance policy registration',
    subTitle: 'Mobile + computer',
    text: 'Mutli-layer OCR feature, categorization, user approval flow, connection to third party databases, user communication, admin panel including cusomtization of the questions to ask, support and investigation.',
    tags: ['Digital strategy', 'Full stack software development'],
    // headerData: {
    //   color: "var(--text, #202020)",
    //   title: "Panda registration",
    //   description: "Your ideal pet insurance Reliable. Digital. Sustainable.",
    // },
  },
  {
    // image: Compass,
    image: getLink('thumb_compass'),
    // hoverImage: CompassHoverImg,
    hoverImage: getLink('thumb_compass_back'),
    href: '/projects/management_solution',
    wrapper_styles: {
      height: '122.5%',
      width: '115.5%',
      top: '-13%',
      left: '-9%',
    },
    title: 'Comprehensive business management solution',
    subTitle: 'Mobile + computer',
    text: 'Project and client management (client page, project page), team management (business units, workload, bookings, timesheet) , Profitability management (pricing, invoice management, budget, reporting)',
    tags: ['Full stack software development'],
    // headerData: {
    //   image: {
    //     decstop: CompassHeaderImg,
    //     tablet: CompassHeaderTabletImg,
    //     mobile: CompassHeaderMobileImg,
    //   },
    //   class: "compass",
    //   title: "Compass",
    //   description:
    //     "A platform which allow a global and wide spread company to harmonize their standards",
    // },
  },
  {
    image: getLink('thumb_claims'),
    hoverImage: getLink('thumb_claims_back'),
    href: '/projects/claims',
    wrapper_styles: {
      height: '116%',
      width: '114%',
      top: '-8%',
      left: '-6%',
    },
    title: 'Claims Portal',
    subTitle: 'Mobile + computer',
    text: 'Customer portal for submitting claims with automated checks and customisable question flow. AI-driven adjudication decisions are available to administrators.',
    tags: ['Digital strategy', 'AI', 'Full stack software development'],
  },
  {
    image: getLink('thumb_u-certify'),
    hoverImage: getLink('thumb_u-certify_back'),
    href: '/projects/learning_platform',
    wrapper_styles: {
      height: '116%',
      width: '114%',
      top: '-8%',
      left: '-6%',
    },
    title: 'Learning platform',
    subTitle: 'Computer',
    text: 'Create, automate, explore, enroll, review different themes and roles credentials and earn verified certificates and badges.',
    tags: ['Automated scripts', 'Full stack software development'],
  },
  {
    // image: Datamorph,
    image: getLink('thumb_datamorph'),
    // hoverImage: DatamorphHoverImg,
    hoverImage: getLink('thumb_datamorph_back'),
    href: '/projects/datamorph',
    wrapper_styles: {
      height: '106%',
      width: '107%',
      top: '0%',
      left: '0%',
    },
    title: 'Secure global data management',
    subTitle: 'Computer',
    text: 'Upload data, metadata management, radnmonization of data, role management, BI marketplace',
    tags: [
      'Security management',
      'Data management',
      'Full stack software development',
    ],
    // headerData: {
    //   color: "var(--text, #202020)",
    //   title: "Compass",
    //   description:
    //     "Building your business intelligence reports has never been easier. With these tools, we help you to go quicker, deeper into the analysis, with confidentiality and consistency by design. Future is now.",
    // },
    bodyData: {},
  },
  {
    // image: Premier,
    image: getLink('thumb_premier'),
    // hoverImage: PremierHoverImg,
    hoverImage: getLink('thumb_premier_back'),
    href: '/projects/navigation_system',
    wrapper_styles: {
      height: '116%',
      width: '114%',
      top: '-8%',
      left: '-6%',
    },
    title: 'Boosted navigation system',
    subTitle: 'Mobile + computer',
    text: 'Kender recommended routes, create custom routes, access meaningful data points, edit and optimize routes, access to your favorite GPS provider',
    tags: [
      'Digital strategy',
      'Machine learning',
      'AI',
      'BI',
      'Data pipeline',
      'Full stack software development',
    ],
    // headerData: {
    //   class: "compass",
    //   title: "Premier",
    //   description:
    //     "A platform which allow a global and wide spread company to harmonize their standards",
    // },
  },

  {
    // image: Anemon,
    image: getLink('thumb_anemon'),
    // hoverImage: AnemonHoverImg,
    hoverImage: getLink('thumb_anemon_back'),
    href: '/projects/training_platform',
    wrapper_styles: {
      height: '113%',
      width: '110.5%',
      top: '-8%',
      left: '-5%',
    },
    title: 'SMS Training platform',
    subTitle: 'Computer',
    text: 'Training creation, two-ways messaging, shortened links to custom forms or media, multi-client management.',
    tags: ['AI', 'Data pipeline', 'Full stack software development'],
    // headerData: {
    //   color: "var(--text, #202020)",
    //   title: "Anemon",
    //   description:
    //     "A platform which allow a global and wide spread company to harmonize their standards",
    // },
  },
  {
    // image: Onpoint,
    image: getLink('thumb_onpoint'),
    // hoverImage: OnpointHoverImg,
    hoverImage: getLink('thumb_onpoint_back'),
    href: '/projects/opportunity_generator',
    wrapper_styles: {
      height: '133%',
      width: '126.5%',
      top: '-19.5%',
      left: '-17%',
    },
    title: 'Opportunity generator in pharmacy industry',
    subTitle: 'Computer',
    text: 'Customizable KPIs, low level rule determination admin panel, investigations and communication.',
    tags: [
      'Digital strategy',
      'Machine learning',
      'AI',
      'Data pipeline',
      'Full stack software development',
    ],
    // headerData: {
    //   color: "var(--text, #202020)",
    //   title: "OnPoint",
    //   description: "Your ideal pet insurance Reliable. Digital. Sustainable.",
    // },
  },
  {
    image: getLink('thumb_flyer'),
    href: '/projects/flyer',
    title: 'Flyer program platform',
    subTitle: 'Computer',
    text: 'Flyer is a mobility and resources pooling project a platform designed to allow all employees to engage in sponsored experiences abroad.',
    tags: [
      'Data management',
      'Security management',
      'Machine learning',
      'AI',
      'Full stack software development',
    ],
  },
  {
    image: getLink('thumb_engage'),
    href: '/projects/engage',
    title: 'Engage',
    subTitle: 'Mobile + computer',
    text: 'A platform allows you to create action plans for projects and follow the entire process. The main flow of the platform consist of creating actions that can be fulfilled by the users in exchange of rewards.',
    tags: ['Automated scripts', 'Full stack software development'],
  },
  {
    image: getLink('thumb_astellas'),
    href: '/projects/astellas',
    title: 'Due diligence platform',
    subTitle: 'Computer',
    text: 'A platform for managing due diligence reports. Organize requests and monitor the status of each due diligence project.',
    tags: ['Automated scripts', 'Full stack software development'],
  },
  {
    image: getLink('thumb_fin_crimes'),
    href: '/projects/fin_crimes',
    title: 'FinCrimes',
    subTitle: 'Computer',
    text: 'Advanced financial crimes questionnaire and automated result generation help you to achieve insights faster and effortlessly.',
    tags: ['Automated scripts', 'Full stack software development'],
  },
  {
    image: getLink('thumb_use_case'),
    href: '/projects/use_case',
    title: 'Use Case',
    subTitle: 'Computer',
    text: 'UseCase helps teams create, prioritize, and track use cases efficiently. Assess complexity, set priorities, and monitor progress with real-time updates and notifications. ',
    tags: ['Automated scripts', 'Full stack software development'],
  },
];

import React from "react";
import "./SchedulePopup.scss";

export const SchedulePopup = (props) => {
  let scheduleDiscussion = props.isOpen
    ? "https://outlook.office.com/bookwithme/user/7c3b1a344b3b47049e012be8d45331a5%400416d70d-c65d-4c29-9f82-ac7e26ac192f/meetingtype/38da894b-a9f2-43e2-9869-a4ecac38a9fc?anonymous"
    : "";
  return (
    <div
      className="overlay"
      onClick={props.closeModal}
      style={props.isOpen ? { display: "block" } : { display: "none" }}
    >
      <div className="container slow-show">
        <div className="body">
          <iframe
            className="book-time-block"
            src={scheduleDiscussion}
            title="External website"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

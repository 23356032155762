import React from "react";

export default function BI(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 3C16.5 2.60218 16.658 2.22064 16.9393 1.93934C17.2206 1.65804 17.6022 1.5 18 1.5H21C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V21H23.25C23.4489 21 23.6397 21.079 23.7803 21.2197C23.921 21.3603 24 21.5511 24 21.75C24 21.9489 23.921 22.1397 23.7803 22.2803C23.6397 22.421 23.4489 22.5 23.25 22.5H0.75C0.551088 22.5 0.360322 22.421 0.21967 22.2803C0.0790176 22.1397 0 21.9489 0 21.75C0 21.5511 0.0790176 21.3603 0.21967 21.2197C0.360322 21.079 0.551088 21 0.75 21H1.5V16.5C1.5 16.1022 1.65804 15.7206 1.93934 15.4393C2.22064 15.158 2.60218 15 3 15H6C6.39782 15 6.77936 15.158 7.06066 15.4393C7.34196 15.7206 7.5 16.1022 7.5 16.5V21H9V10.5C9 10.1022 9.15804 9.72064 9.43934 9.43934C9.72064 9.15804 10.1022 9 10.5 9H13.5C13.8978 9 14.2794 9.15804 14.5607 9.43934C14.842 9.72064 15 10.1022 15 10.5V21H16.5V3ZM18 21H21V3H18V21ZM13.5 21V10.5H10.5V21H13.5ZM6 21V16.5H3V21H6Z"
        fill="#434343"
      />
    </svg>
  );
}

import React, { useEffect, useState } from 'react';
import './Header.scss';
import BlueLogo from '../../assets/brands/ForvisMazars-Logo-Color.png';
import ArrowDown from '../../assets/arrows/arrow-down-white.svg';
// import ArrowUp from "../../assets/header/arrow_down_black.svg";
import Underline from '../../assets/header/underline.svg';
// import ArrowDownCircle from "../../assets/header/arrow_down_circle.svg";
import BurgerMenu from '../../assets/header/burger-menu.svg';
import Cross from '../../assets/header/cross_white.svg';
import { HEADER_LINKS } from '../../constants/AppConstants';
import { NavLink, useNavigate } from 'react-router-dom';
// import { HeaderData } from "../../data/HeaderData";
// import { useCustomContext } from "../../context/Context";
import { ScheduleADiscutionBtn } from '../Buttons/ScheduleADiscutionBtn';

const Header = (props) => {
  const router = useNavigate();

  const [currentWidth, setCurrentWidth] = useState(null);

  const path = props.path;
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const refBtn = useRef(null);

  // const [isBurger, setIsBurger] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  // const { setHeaderRef, setCurrentBlock } = useCustomContext();
  // const headerRef = useRef(null);

  // const {
  //   headerRef,
  // } = useCustomContext();

  // const scrollToBody = () => {
  //   const screenHeight = window.innerHeight;
  //   window.scrollTo({
  //     top: screenHeight + 1,
  //     behavior: "smooth",
  //   });
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const sidebarOpen = (open) => {
    setIsBurgerOpen(open);
    const domElement = document.getElementsByClassName('burger-sidebar');
    if (open) {
      domElement[0].classList.add('sidebar-open');
    } else domElement[0].classList.remove('sidebar-open');
  };

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
    // if (window.innerWidth <= 900) setIsBurger(true);
    // else setIsBurger(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // useEffect(() => {
  //   setHeaderRef(headerRef);
  //   setCurrentBlock(headerRef);
  // }, []);

  // useEffect(() => {
  //   const targetDivs = document.getElementsByClassName("content-row");
  //   setTimeout(() => {
  //     for (let i = 0; i < targetDivs.length; i++) {
  //       const targetDiv = targetDivs[i];
  //       targetDiv.classList.add("slow-show");
  //     }
  //   });

  //   return () => {
  //     for (let i = 0; i < targetDivs.length; i++) {
  //       const targetDiv = targetDivs[i];
  //       targetDiv.classList.remove("slow-show");
  //     }
  //   };
  // }, [props.path]);

  // useEffect(() => {
  //   console.log("Header > isBurger >", isBurger);
  // }, [isBurger]);

  // useEffect(() => {
  //   const closeMenuHandler = (e) => {
  //     if (
  //       (isMenuOpen && e.target !== refBtn.current) ||
  //       (isMenuOpen && e.target.parentElement === refBtn.current)
  //     ) {
  //       setIsMenuOpen(false);
  //     }
  //   };
  //   document.addEventListener("click", closeMenuHandler);
  //   return () => {
  //     document.removeEventListener("click", closeMenuHandler);
  //   };
  // }, [isMenuOpen]);

  return (
    <header className="header">
      <div
        className="header-main"
        // style={path === "/projects" ? { height: "50vh" } : {}}
      >
        <div className="header-row">
          <div
            className="header-logo"
            onClick={() => {
              sidebarOpen(false);
              setTimeout(() => {
                scrollToTop();
              }, 300);
            }}
          >
            <img src={BlueLogo} alt="logo" onClick={() => router('/')} />
          </div>
          {currentWidth > 900 ? (
            <div className="header-nav">
              {HEADER_LINKS.map((i) => (
                // ---------------------check nav-item class--------------
                <NavLink key={i.href} className="nav-item" to={i.href}>
                  <div
                    className="nav-box"
                    onClick={() => {
                      sidebarOpen(false);
                      setTimeout(() => {
                        scrollToTop();
                      }, 300);
                    }}
                  >
                    {i.title}
                    {i.href === path && <img src={Underline} alt="Underline" />}
                  </div>
                </NavLink>
              ))}
              {/* <button className="m-button contact-us-btn m-button-blue">
                <span>Contact us</span>
                <img src={ArrowDown} alt="" />
              </button> */}

              {/* <ContactUsDropdown /> */}
            </div>
          ) : (
            <div className="header-row-buttons">
              {/* {window.innerWidth > 600 && (
                // <div className="button-white-header">Schedule a discussion</div>
                <ScheduleADiscutionBtn />
              )} */}
              <div className="header-burger-button">
                <img
                  src={BurgerMenu}
                  alt="BurgerMenu"
                  onClick={() => {
                    sidebarOpen(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* <div
          className={
            ["/how-we-work", "/our-services"].includes(path)
              ? "header-content-hww"
              : "header-content"
          }
        >
          <div className="content-row">
            <div
              className="content-box"
              style={path === "/projects" ? { alignItems: "center" } : {}}
            >
              <h1>
                {path !== "/"
                  ? HEADER_LINKS.filter((el) => el.href === path)[0].title
                  : "Mazars’ Digital Services"}
              </h1>
              <div
                className="content-text"
                style={path === "/projects" ? { textAlign: "center" } : {}}
              >
                {path !== "/"
                  ? HEADER_LINKS.filter((el) => el.href === path)[0].contentText
                  : "We provide full-stack development services for a variety of platforms and deliver a product fit for your purpose."}
              </div>
            </div>
            {path !== "/projects" && (
              <div className="content-image">
                <img src={HeaderData[path]} alt="Page image" />
              </div>
            )}
          </div>
        </div> */}
        {/* {!["/how-we-work", "/our-services", "/projects"].includes(path) && (
          <div className="header-arrow-down" onClick={scrollToBody}>
            <img src={ArrowDownCircle} alt="Arrow down" />
          </div>
        )} */}
      </div>
      <div>
        <div className={isBurgerOpen ? 'burger-background' : ''}>
          <div className="burger-sidebar">
            <img
              src={Cross}
              alt="Close"
              onClick={() => {
                sidebarOpen(false);
              }}
            />
            <div className="burger-navigation">
              {HEADER_LINKS.map((el, index) => (
                <NavLink key={index} className="burger-nav-link" to={el.href}>
                  <div
                    className="burger-nav-item"
                    onClick={() => {
                      sidebarOpen(false);
                      setTimeout(() => {
                        scrollToTop();
                      }, 300);
                    }}
                  >
                    {el.title}
                  </div>
                </NavLink>
              ))}
            </div>
            {/* <div className="burger-buttons">
              <ScheduleADiscutionBtn />

              <button className="m-button contact-us-btn m-button-blue mobile">
                <span>Contact us</span>
                <img src={ArrowDown} alt="" />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panda-container {
  display: flex;
  flex-direction: column;
  background: #fffefa;
}
.panda-container .image-container img {
  display: flex;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProjectsDescriptions/Panda/Panda.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAEI;EACE,aAAA;EACA,WAAA;EACA,YAAA;AAAN","sourcesContent":[".panda-container {\n  display: flex;\n  flex-direction: column;\n  background: #fffefa;\n\n  .image-container {\n    img {\n      display: flex;\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n\n// .panda-loader {\n//   background-color: #FE4B4E;\n//   position: relative;\n//   width: 100%;\n//   height: calc(100vh - 60px);\n//   animation-name: showPandaBackground;\n//   animation-duration: 5s;\n//   animation-fill-mode: forwards;\n//   animation-iteration-count: infinite;\n//   animation-timing-function: ease;\n\n//   &>img {\n//     position: absolute;\n//     width: 180px;\n//     bottom: -20px;\n//     right: 20px;\n//   }\n\n//   @media screen and (min-width: 600px) {\n//     height: calc(100vh - 70px);\n\n//     &>img {\n//       width: 300px;\n//       bottom: -36px;\n//       right: 100px;\n//     }\n//   }\n\n//   @media screen and (min-width: 900px) {\n//     height: calc(100vh - 100px);\n\n//     &>img {\n//       width: 300px;\n//       bottom: -36px;\n//       right: 300px;\n//     }\n//   }\n// }\n\n// @keyframes showPandaBackground {\n//   0% {\n//     opacity: 0;\n//   }\n\n//   30% {\n//     opacity: 1;\n//   }\n\n//   70% {\n//     opacity: 1;\n//   }\n\n//   100% {\n//     opacity: 0;\n//   }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "./Step.scss";

const Step = ({
  currentWidth,
  index,
  styles,
  position,
  title,
  clippedStep = false,
}) => {
  return (
    <div
      className="step-arrow-container"
      style={{ justifyContent: position, zIndex: `${10 - index}` }}
    >
      <div className="step" style={styles}>
        <div
          className="step-title"
          style={{
            marginLeft: currentWidth < 900 && !clippedStep ? "5px" : "30px",
          }}
        >
          {currentWidth < 900 && clippedStep ? "..." : title}
        </div>

        <div
          className="step-triangle"
          style={{ backgroundColor: styles.backgroundColor }}
        ></div>
        {clippedStep && index !== 0 ? (
          <div
            className="step-triangle"
            style={{
              backgroundColor: "#f7fcff",
              left: "-15px",
              border: "unset",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Step;

import axios from "axios";
import { BASE_URL } from "../constants/config";

const EVENTS_ENDPOINTS = {
  get: `${BASE_URL}api/events`,
  getAll: `${BASE_URL}api/events?all=true`,
  getId: (id) => `${BASE_URL}api/events/${id}`,
};

class Events {
  async getEvents(all = false) {
    const response = await axios.get(
      all ? EVENTS_ENDPOINTS.getAll : EVENTS_ENDPOINTS.get
    );

    return response.data;
  }

  async getEvent(id) {
    const response = await axios.get(EVENTS_ENDPOINTS.getId(id));

    return response.data;
  }

  async createEvent(data) {
    const response = await axios.post(EVENTS_ENDPOINTS.get, data);

    return response.data;
  }

  async editEvent(data, id) {
    const response = await axios.put(EVENTS_ENDPOINTS.getId(id), data);

    return response.data;
  }

  async deleteEvent(id) {
    const response = await axios.delete(EVENTS_ENDPOINTS.getId(id));

    return response.data;
  }
}

export const EventsService = new Events();

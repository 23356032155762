import React, { useState } from "react";

import "./TextField.scss";

const TextField = ({ inputText, handleChange, labelText, type, id, ...otherProps }) => {
  const [active, setActive] = useState(false);

  return (
    <div className="form-group">
      <label
        htmlFor={id}
        className={`${
          active || inputText !== "" ? ` activeLabel` : ""
        } label-float`}
      >
        {labelText}
      </label>
      <input
        type={type}
        className="form-input"
        id={id}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        onChange={handleChange}
        value={inputText}
        {...otherProps}
      />
    </div>
  );
};

export default TextField;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datamorph-container {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.datamorph-container .image-container img {
  display: flex;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProjectsDescriptions/DataMorph/DataMorph.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAEI;EACE,aAAA;EACA,WAAA;EACA,YAAA;AAAN","sourcesContent":[".datamorph-container {\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n\n  .image-container {\n    img {\n      display: flex;\n      width: 100%;\n      height: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

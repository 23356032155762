import React, { useEffect, useState } from "react";
import "./AboutUs.scss";
import Different from "./components/Different";
import { AboutUsData } from "../../data/AboutUs";
import LampWhite from "../../assets/about/lamp_white.svg";
import LampBlue from "../../assets/about/lamp_blue.svg";
import Circle from "../../assets/about/circle.svg";
import ArrowUp from "../../assets/home/arrow_blue_up.svg";
import NeedsSolutions from "./components/NeedsSolutions";

const AboutUs = () => {
  const [isPhone, setisPhone] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 500);
  };

  const onResize = () => {
    if (window.innerWidth <= 600) setisPhone(true);
    if (window.innerWidth > 600) setisPhone(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Different />
      <NeedsSolutions />
      {/* <div className="about-main">
        <Different noButton={true} />
        <div className="about-meeting">
          <h2>Meeting wide variety of needs</h2>
          {isPhone ? (
            <div className="frame-mobile">
              <div className="frame-mobile-needs">
                <div className="frame-mobile-header">Your needs</div>
                <div className="frame-mobile-content">
                  {AboutUsData.map((el, ind) => (
                    <div className="frame-mobile-content-item" key={ind}>
                      {el.need}
                    </div>
                  ))}
                </div>
                <img src={Circle} alt="Circle" />
              </div>
              <div className="frame-mobile-separator"></div>
              <div className="frame-mobile-solutions">
                <div className="frame-mobile-header">Our solutions</div>
                <div className="frame-mobile-content">
                  {AboutUsData.map((el, ind) => (
                    <div className="frame-mobile-content-item" key={ind}>
                      {el.solution}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="frame">
              <div className="frame-header">
                <div className="needs">
                  <span>Your needs</span>
                </div>
                <img src={Circle} alt="Circle" />
                <div className="solutions">
                  <span>Our solutions</span>
                </div>
              </div>
              <div className="frame-separator"></div>
              <div className="frame-content">
                {AboutUsData.map((el, index) => (
                  <div
                    className="frame-content-item"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="need">{el.need}</div>
                    <img
                      src={hoveredIndex === index ? LampBlue : LampWhite}
                      alt="Lamp"
                    />
                    <div className="solution">{el.solution}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="button-to-start">
          <img src={ArrowUp} alt="ArrowUp" onClick={scrollToTop} />
        </div>
      )} */}
    </div>
  );
};

export default AboutUs;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintenance-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  gap: 50px;
  padding: 20px;
}
@media screen and (min-width: 600px) {
  .maintenance-container {
    padding: 50px 30px;
    gap: 80px;
  }
}
@media screen and (min-width: 900px) {
  .maintenance-container {
    padding: 50px;
    gap: 90px;
  }
}
.maintenance-container .image-container img {
  display: flex;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Maintenance/Maintenance.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;EACA,aAAA;AACJ;AAEM;EARN;IASQ,kBAAA;IACA,SAAA;EACN;AACF;AACM;EAbN;IAcQ,aAAA;IACA,SAAA;EAEN;AACF;AACM;EACE,aAAA;EACA,WAAA;EACA,YAAA;AACR","sourcesContent":[".maintenance-container {\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n    gap: 50px;\n    padding: 20px;\n  \n \n      @media screen and (min-width: 600px) {\n        padding: 50px 30px;\n        gap: 80px;\n\n      }\n      @media screen and (min-width: 900px) {\n        padding: 50px;\n        gap: 90px;\n      }\n\n    .image-container {\n      img {\n        display: flex;\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import ProjectsComponent from "../../components/Projects";
import ArrowUp from "../../assets/home/arrow_blue_up.svg";
import "./Projects.scss";

const Projects = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 500);
  };

  useEffect(() => {
    // scrollToTop();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="m-card" style={{ backgroundColor: "#3D4975" }}>
      <div className="m-card__content" style={{ backgroundColor: "#fff" }}>
        <ProjectsComponent noTitle={true} noButton={true} />
        {isVisible && (
          <div className="button-to-start">
            <img src={ArrowUp} alt="ArrowUp" onClick={scrollToTop} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;

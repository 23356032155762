import vue from "../assets/tech_stack/frontend/vue.png";
import nextJS from "../assets/tech_stack/frontend/nextJS.png";
import react from "../assets/tech_stack/frontend/react.png";
import HTML_CSS_JS from "../assets/tech_stack/frontend/html_css_js.png";

import node from "../assets/tech_stack/backend/node.png";
import fastAPI from "../assets/tech_stack/backend/fastAPI.png";
import flask from "../assets/tech_stack/backend/flask.png";

import rabbitMQ from "../assets/tech_stack/scale_manage/rabbitMQ.png";
import docker from "../assets/tech_stack/scale_manage/docker.png";
import sentry from "../assets/tech_stack/scale_manage/sentry.png";
import nginx from "../assets/tech_stack/scale_manage/nginx.png";
import gunicorn from "../assets/tech_stack/scale_manage/gunicorn.png";
import netdata from "../assets/tech_stack/scale_manage/netdata.png";
import traefik from "../assets/tech_stack/scale_manage/traefik.jpg";
import datadog from "../assets/tech_stack/scale_manage/datadog.png";

import github from "../assets/tech_stack/orchestrate/github.png";
import azure from "../assets/tech_stack/orchestrate/azure.png";
import gitlab from "../assets/tech_stack/orchestrate/gitlab.png";

import mongoDB from "../assets/tech_stack/store/mongoDB.png";
import firebase from "../assets/tech_stack/store/firebase.png";
import some1001 from "../assets/tech_stack/store/1001.png";
import mySQL from "../assets/tech_stack/store/mySQL.png";
import SQL from "../assets/tech_stack/store/SQL.png";
import googleBigQuery from "../assets/tech_stack/store/googleBigQuery.png";
import snowflake from "../assets/tech_stack/store/snowflake.png";

import OWHcloud from "../assets/tech_stack/host/OVHcloud.png";
import hexagon from "../assets/tech_stack/host/hexagon.png";
import aws from "../assets/tech_stack/host/aws.png";
import azureCloud from "../assets/tech_stack/host/A.png";
import digitalOcean from "../assets/tech_stack/host/digitalOcean.png";

import stitch from "../assets/tech_stack/extract_transform_load/stitch.png";
import talend from "../assets/tech_stack/extract_transform_load/talend.png";
import alteryx from "../assets/tech_stack/extract_transform_load/alteryx.png";
import socket from "../assets/tech_stack/extract_transform_load/socket.png";
import lightning from "../assets/tech_stack/extract_transform_load/lightning.png";
import python from "../assets/tech_stack/extract_transform_load/python.png";

export const TechnologyStackData = [
  {
    title: "Frontend",
    techItems: [vue, nextJS, react, HTML_CSS_JS],
  },
  {
    title: "Backend",
    techItems: [node, fastAPI, flask],
  },
  {
    title: "Scale / Manage",
    techItems: [
      rabbitMQ,
      docker,
      sentry,
      nginx,
      gunicorn,
      netdata,
      traefik,
      datadog,
    ],
  },
  {
    title: "Orchestrate",
    techItems: [github, azure, gitlab],
  },
  {
    title: "Store",
    techItems: [
      mongoDB,
      firebase,
      some1001,
      mySQL,
      SQL,
      googleBigQuery,
      snowflake,
    ],
  },
  {
    title: "Host",
    techItems: [OWHcloud, hexagon, aws, azureCloud, digitalOcean],
  },
  {
    title: "Extract / Transform / Load",
    techItems: [stitch, talend, alteryx, socket, lightning, python],
  },
];

export const TechnologySchemeData = {
  header: {
    host: {
      digitalOcean: {
        img: digitalOcean,
        label: "DigitalOcean",
        description: "some text",
        id: "digitalOcean",
      },
      azureCloud: {
        img: azureCloud,
        label: "azureCloud",
        description: "some text",
        id: "azureCloud",
      },
      aws: { label: "AWS", img: aws, description: "some text", id: "aws" },
      googleCloud: {
        img: hexagon,
        label: "AWS",
        description: "some text",
        id: "googleCloud",
      },
      OVHCloud: {
        img: OWHcloud,
        label: "OVHCloud",
        description: "some text",
        id: "OVHCloud",
      },
    },
    "CI/CD": {
      gitLab: {
        img: gitlab,
        label: "GitLab",
        description: "some text",
        id: "gitLab",
      },
      gitHub: {
        img: github,
        label: "GitHub",
        description: "some text",
        id: "gitHub",
      },
      azureDevOps: {
        img: azureCloud,
        label: "Azure devOps",
        description: "some text",
        id: "azureDevOps",
      },
    },
  },
  body: {
    SSO: {},
    Frontend: {
      vue: { img: vue, label: "Vue", description: "some text", id: "vue" },
      react: {
        img: react,
        label: "React",
        description: "some text",
        id: "react",
      },
      nextJS: {
        img: nextJS,
        label: "nextJS",
        description: "some text",
        id: "nextJS",
      },
    },
    Backend: {
      flask: {
        img: flask,
        label: "Flask",
        description:
          "Flask - Quick and Easy Web Development:\n\nFlask is a user-friendly web development technology that allows you to create websites quickly and easily. With its simple and straightforward approach, Flask simplifies the process of building web applications, even for non-technical users.\n\nFlexible and Expandable:\n\nFlask offers a flexible platform that can be tailored to meet your specific needs. Whether you're starting with a small project or planning to scale up, Flask allows you to customize and expand your application as your requirements evolve.\n\nLightweight and Fast:\n\nFlask is known for its lightweight nature, which means it doesn't require excessive resources to run. This results in faster loading times and efficient performance for your website, providing a smooth and responsive user experience.\n\nSupported by a Thriving Community:\n\nFlask benefits from a large and active community of developers who contribute to its growth and provide support. This means you can find a wealth of resources, tutorials, and documentation to help you along the way, making your Flask development journey easier and more enjoyable.\n\nIn summary, Flask offers a user-friendly and efficient way to build websites. Its flexibility, lightweight design, and strong community support make it an excellent choice for users seeking a technology that is both accessible and capable of delivering high-quality web applications.",
        id: "flask",
      },
      fastAPI: {
        img: fastAPI,
        label: "fastAPI",
        description: "some text",
        id: "fastAPI",
      },
      node: {
        img: node,
        label: "Node",
        description: "some text",
        id: "node",
      },
    },
    Database: {
      mongoDB: {
        img: mongoDB,
        label: "MongoDB",
        description: "some text",
        id: "mongoDB",
      },
      firebase: {
        img: firebase,
        label: "Firebase",
        description: "some text",
        id: "firebase",
      },
      azure1001: {
        img: some1001,
        label: "1001",
        description: "some text",
        id: "azure1001",
      },
      mySQL: {
        img: mySQL,
        label: "mySQL",
        description: "some text",
        id: "mySQL",
      },
      SQL: { img: SQL, label: "SQL", description: "some text", id: "SQL" },
      googleBigQuery: {
        img: googleBigQuery,
        label: "googleBigQuery",
        description: "some text",
        id: "googleBigQuery",
      },
      snowFlake: {
        img: snowflake,
        label: "snowFlake",
        description: "some text",
        id: "snowFlake",
      },
    },
  },
  footer: {
    "Other services": {
      sentry: {
        img: sentry,
        label: "Sentry",
        description: "some text",
        id: "sentry",
      },
      docker: {
        img: docker,
        label: "Docker",
        description: "some text",
        id: "docker",
      },
      gunicorn: {
        img: gunicorn,
        label: "Gunicorn",
        description: "some text",
        id: "gunicorn",
      },
      nginx: {
        img: nginx,
        label: "Nginx",
        description: "some text",
        id: "nginx",
      },
      traefik: {
        img: traefik,
        label: "Traefic",
        description: "some text",
        id: "traefik",
      },
      rabbitMQ: {
        img: rabbitMQ,
        label: "RabbitMQ",
        description: "some text",
        id: "rabbitMQ",
      },
      stich: {
        img: stitch,
        label: "Stich",
        description: "some text",
        id: "stich",
      },
      talend: {
        img: talend,
        label: "Talent",
        description: "some text",
        id: "talend",
      },
      alteryx: {
        img: alteryx,
        label: "Alteryx",
        description: "some text",
        id: "alteryx",
      },
      powerAutomate: {
        img: socket,
        label: "Power automate",
        description: "some text",
        id: "powerAutomate",
      },
      azureFunction: {
        img: lightning,
        label: "Azure function",
        description: "some text",
        id: "azureFunction",
      },
    },
  },
};

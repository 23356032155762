import React from "react";

export default function MachineLearning(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 8.25V3.75H18V5.25H10.5V3C10.4994 2.60236 10.3412 2.22117 10.06 1.94C9.77883 1.65882 9.39764 1.5006 9 1.5H3C2.60236 1.5006 2.22117 1.65882 1.94 1.94C1.65882 2.22117 1.5006 2.60236 1.5 3V9C1.5006 9.39764 1.65882 9.77883 1.94 10.06C2.22117 10.3412 2.60236 10.4994 3 10.5H9C9.39764 10.4994 9.77883 10.3412 10.06 10.06C10.3412 9.77883 10.4994 9.39764 10.5 9V6.75C11.0966 6.7506 11.6685 6.98784 12.0903 7.40967C12.5122 7.8315 12.7494 8.40345 12.75 9V15.75H8.25V14.25H3.75V18.75H8.25V17.25H12.75V18.75C12.7506 19.1476 12.9088 19.5288 13.19 19.81C13.4712 20.0912 13.8524 20.2494 14.25 20.25H18V21.75H22.5V17.25H18V18.75H14.25V13.5H18V15H22.5V10.5H18V12H14.25V9C14.2472 8.18602 13.9771 7.39549 13.4813 6.75H18V8.25H22.5ZM3 9V3H9V9H3ZM6.75 17.25H5.25V15.75H6.75V17.25ZM19.5 18.75H21V20.25H19.5V18.75ZM19.5 12H21V13.5H19.5V12ZM19.5 5.25H21V6.75H19.5V5.25Z"
        fill="#434343"
      />
    </svg>
  );
}
